import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

// console.log(token);

const api = axios.create({
  baseURL: "https://d1u6gj5dan20j2.cloudfront.net/",
  // baseURL: "https://api.sechatacademy.com.br",
  // baseURL: "http://localhost:3000",
  headers: {
    Authorization: cookies.get("sechat-token"),
  },
});

export default api;
