import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./pages/Login";
import Medicines from "./pages/Medicine";
import Users from "./pages/Users";
import UserRegister from "./pages/UserRegister";
import SearchHistory from "./pages/SearchHistory";
import { PermifyProvider } from "@permify/react-role";
import SuggestedMedicines from "./pages/SuggestedMedicines";
import Medicos from "./pages/Medicos";
import MedicineRegister from "./pages/MedicineRegister";
import PasswordRecovery from "./pages/PasswordRecovery";
import Register from "./pages/Register";
import FirstLogin from "./pages/FirstLogin";
import Support from "./pages/Support";
import Courses from "./pages/courses/Course";
import CourseRegister from "./pages/courses/CourseRegister";
import { SnackbarProvider } from "notistack";
import CourseView from "./pages/courses/CourseView";
import CourseEdit from "./pages/courses/CourseEdit";
import ViewUsersPermissions from "./pages/courses/ViewUsersPermissions";

const App = () => {
  return (
    <PermifyProvider>
      <Router>
        <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
          <>
            <Routes>
              <Route exact path="/" element={<PrivateRoute />}>
                {/* Home ------------------------------------------------->  */}
                <Route exact path="/" element={<SuggestedMedicines />} />
                {/* Medicine --------------------------------------------->  */}
                <Route
                  exact
                  path="/suggestedMedicines"
                  element={<SuggestedMedicines />}
                />
                <Route exact path="/medicines" element={<Medicines />} />
                <Route
                  exact
                  path="/medicinesRegister"
                  element={<MedicineRegister />}
                />
                <Route exact path="/mySearch" element={<SearchHistory />} />
                {/* Curso --------------------------------------------->  */}
                <Route exact path="/courses" element={<Courses />} />
                <Route
                  exact
                  path="/coursesRegister"
                  element={<CourseRegister />}
                />
                <Route path="/courses/:id" element={<CourseView />} />
                <Route path="/courses/edit/:id" element={<CourseEdit />} />
                <Route
                  path="/courses/viewusers/:id"
                  element={<ViewUsersPermissions />}
                />
                {/* Users ------------------------------------------------>  */}
                <Route exact path="/users" element={<Users />} />
                <Route exact path="/userRegister" element={<UserRegister />} />
                {/* Medicos ----------------------------------------------> */}
                <Route exact path="/medicos" element={<Medicos />} />
                <Route exact path="/support" element={<Support />} />
              </Route>
              {/* Login --------------------------------------------------> */}
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/recovery" element={<PasswordRecovery />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="first-login" element={<FirstLogin />} />
            </Routes>
          </>
        </SnackbarProvider>
      </Router>
    </PermifyProvider>
  );
};

export default App;
