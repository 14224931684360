import React from 'react'
import styled from 'styled-components';
import { FiMenu } from "react-icons/fi";
import COLORS from '../../constants/colors';
import SCREEN_SIZES from '../../constants/screenSizes';

function BurgerMobile({ onClick }) {
    return (
        <Container onClick={onClick}>
            <FiMenu stroke={COLORS.white} size={26} />
        </Container>
    )
}

const Container = styled.a`
    cursor: pointer;
    display: none;
    @media only screen and (max-width: ${SCREEN_SIZES.md}) {
        display: block;
    }
`;

export default BurgerMobile