import React from 'react';
import styled from 'styled-components';

const InputEmail = styled.input`
  width: 100%;
  height: 36px;
  padding: 10px;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 16px;
  outline: 0;
  border: 1px solid #d5d5d5;
`;

const LabelEmail = styled.label`
  font-family: 'Public Sans', sans-serif;
  font-size: 15px;
  color: #7c94a4;
`;

const LoginInput = (props) => {
  return (
    <div>
      <div>
        <LabelEmail>{props.labeltext}</LabelEmail>
      </div>
      <div>
        <InputEmail onChange={props.onChange} type={props.type} />
      </div>
    </div>
  );
};

export default LoginInput;
