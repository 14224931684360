import React, { useState } from "react";
import styled from "styled-components";
import COLORS from "../../constants/colors";

const ButtonX = (props) => {
  return (
    <Btn style={props.style} type="button" onClick={props.onClick}>
      {props.children}
    </Btn>
  );
};

const Btn = styled.button`
  max-height: 20px;
  border-radius: 4px;
  font-family: "Public Sans", sans-serif;
  font-size: 18px;
  cursor: pointer;
  background: transparent;
  color: ${COLORS.white};
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #bbb;
  font-weight: 500;

  &:hover {
    transition: all 0.3s ease;
  }
`;

export default ButtonX;
