import React from 'react'
import Root from '../components/organisms/Root';
import TableUsers from '../components/molecules/TableUsers'
import { HasAccess } from '@permify/react-role';

function Users() {
    return (
        <Root active={'users'}>
            <HasAccess
                roles={["Administrador"]}
                renderAuthFailed={<p>Erro: Você não tem permissão para acessar essa tela.</p>}
            >
                <TableUsers tableName="Usuários" />
            </HasAccess>
        </Root>
    );
}


export default Users