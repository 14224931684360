import React, { useState } from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/colors';

const Button = (props) => {
  return (
    <Btn style={props.style} type={props.type} onClick={props.onClick}>
      {props.children}
    </Btn>
  );
};

const Btn = styled.button`
  padding: 12px;
  max-height: 44px;
  border-radius: 4px;
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  cursor: pointer;
  background: ${COLORS.green};
  color: ${COLORS.white};
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  &:hover {
    background: ${COLORS.greenAlternate};
    transition: all 0.3s ease;
  }
`;

export default Button;
