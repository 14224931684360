import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";

const PrivateRoute = () => {
  const cookies = new Cookies();
  const auth = cookies.get("sechat-token");
  const first_access = cookies.get("first_access");
  if (first_access) {
    if (JSON.parse(first_access) && auth) {
      return <Navigate to="/first-login" />;
    } else {
      return auth ? <Outlet /> : <Navigate to="/login" />;
    }
  }
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
