import React from "react";
import { HasAccess, usePermify } from "@permify/react-role";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { RiInformationLine } from "react-icons/ri";
import COLORS from "../../../constants/colors";
import SCREEN_SIZES from "../../../constants/screenSizes";
import api from "../../../services/api";
import Button from "../../atoms/Button";
import WizardBody from "../Wizard/WizardBody";
import formatData from "../../atoms/formatData";
import { AiOutlineCloudDownload } from "react-icons/ai";
import "../../../styles/style.css";

function ViewCourse(props) {
  const navigate = useNavigate();
  const cursoId = props.cursoId;
  const [loaded, setLoaded] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [dateStart, setdateStart] = useState("");
  const [dateEnd, setdateEnd] = useState("");
  const [links, setLinks] = useState([]);
  const [banner, setBanner] = useState("");
  const [filesList, setFilesList] = useState([]);
  const [containFile, setContainFile] = useState(false);
  const files = [];

  const inputFile = document.querySelector("#picture__input");
  const pictureImage = document.querySelector(".picture__image");

  useEffect(() => {
    getcurso(cursoId);
  }, []);

  const getcurso = async (cursoId) => {
    setLoaded(false);
    await api.get(`/cursos/${cursoId}`).then(async (results) => {
      setName(results.data.result.nome);
      setDescription(results.data.result.descricao);
      setdateStart(results.data.result.dataInicio);
      setdateEnd(results.data.result.dataFim);

      if (results.data.banner) {
        await api
          .get(
            `/cursos/uploadFiles/donwloadBanner/${cursoId}/${results.data.banner}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            const file = new Blob([response.data], {
              type: response.data.type,
            });

            const fileURL = URL.createObjectURL(file);

            setBanner(fileURL);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    await api
      .get(`/linkscursos/${cursoId}`)
      .then((response) => {
        if (response.data.length > 0) {
          response.data.map((l) => {
            links.push(l.descricao);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    await api
      .get(`/cursos/uploadFiles/${cursoId}`, {})
      .then((response) => {
        if (response.data.length > 0) {
          setContainFile(true);
        }
        for (let i = 0; i < response.data.length; i++) {
          files.push(response.data[i]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const renderList = files.map((item, index) => (
      <div key={index}>{item}</div>
    ));

    setFilesList(renderList);
    setLoaded(true);
  };

  const downloadFile = async (file) => {
    const fileDonwload = file.props.children;
    await api
      .get(`/cursos/uploadFiles/donwloadFiles/${cursoId}/${fileDonwload}`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], {
          type: response.data.type,
        });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {name && (
        <Card>
          <WizardBody>
            <>
              {banner ? (
                <label class="picture" htmlFor="picture__input" tabIndex="0">
                  <span class="picture__image">
                    <img src={banner} class="picture__img" />
                  </span>
                </label>
              ) : (
                <InputContainer>
                  <CardTitle>{name}</CardTitle>
                </InputContainer>
              )}
              <InputGroup>
                <InputGroup>
                  <CardText>Data de início: {formatData(dateStart)}</CardText>
                </InputGroup>
                <CardText>Data do fim: {formatData(dateEnd)}</CardText>
              </InputGroup>
              <CardTT>
                Descrição: <CardText>{description}</CardText>
              </CardTT>

              <CardTT>
                Links:{" "}
                <CardText>
                  {links.length > 0 ? (
                    links.map((link) => {
                      return (
                        <Link href={link} target="_blank">
                          {link}
                        </Link>
                      );
                    })
                  ) : (
                    <Link>Nenhum link para exibir</Link>
                  )}{" "}
                </CardText>
              </CardTT>

              <CardTT>
                Arquivos:
                <Container>
                  {containFile &&
                    filesList.map((file, index) => {
                      return (
                        <Button
                          onClick={() => downloadFile(file)}
                          style={{
                            padding: "12px 24px",
                            background: COLORS.softWhite2,
                            color: COLORS.blue,
                          }}
                        >
                          {file} <AiOutlineCloudDownload size={"20px"} />
                        </Button>
                      );
                    })}

                  {!containFile && (
                    <CardText>Nenhum arquivo foi inserido nesse curso</CardText>
                  )}
                </Container>
              </CardTT>
              <ActionGroup>
                <Button
                  onClick={() => {
                    navigate(`/courses`);
                  }}
                  style={{
                    padding: "12px 24px",
                    background: COLORS.softWhite2,
                    color: COLORS.blue,
                  }}
                >
                  Voltar
                </Button>
              </ActionGroup>
            </>
          </WizardBody>
        </Card>
      )}
    </>
  );
}

const Card = styled.div`
  max-width: 1000px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
  border-radius: 4px;
`;

const CardTitle = styled.h1`
  margin-top: 16px;
  font-weight: 500;
  font-size: 2rem;
  align-self: center;
  color: #fff;
`;

const CardText = styled.h6`
  margin-top: 8px;
  font-weight: 400;
  font-size: 1.2rem;
`;

const CardTT = styled.h6`
  margin-top: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  align-self: center;
`;

const Link = styled.a`
  display: flex;
  color: ${COLORS.blue};
  &:hover {
    color: ${COLORS.blue};
    transition: all 0.3s ease;
  }
`;

const ActionGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 20px;
`;

const Container = styled.div`
  padding: 15px;
  height: auto;
  flex-wrap: wrap;
  border: 1px solid ${COLORS.softWhite2};
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 0;
  flex-grow: 1;
  border-radius: 15px;
  outline: none;
  width: 100%;
  background-color: ${COLORS.green};
  border: 1px solid ${COLORS.softWhite2};
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 100%;
  }
`;

export default ViewCourse;
