import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import WizardBody from "../molecules/Wizard/WizardBody";
import useWizardHeader from "../molecules/Wizard/useWizardHeader";
import styled from "styled-components";
import COLORS from "../../constants/colors";
import Button from "../atoms/Button";
import { BsArrowRight } from "react-icons/bs";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import api from "../../services/api";
import SCREEN_SIZES from "../../constants/screenSizes";
import Cookies from "universal-cookie";
import ButtonX from "../atoms/ButtonX";
import { useSnackbar } from "notistack";
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import "../../styles/style.css";

function Wizard({
  course = {
    id: "",
    nome: "",
    descricao: "",
    dataInicio: "",
    dataFim: "",
    links: [],
    filesList: [],
  },
}) {
  const header = ["Informações Gerais Curso", "Permissões de acesso"];
  const cookies = new Cookies();
  const { tabActive, setTabActive, tabFilled, setTabFilled, render } =
    useWizardHeader(header);
  const [links, setLinks] = useState([]);
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState("");
  const [users, setUsers] = useState("");
  const [errors, setErrors] = useState("");
  const [idUsers, setIdUsers] = useState([]);
  const [idCourse, setIdCourse] = useState("");
  const [selectTodos, setTodos] = useState(false);
  const [filesEdit, setFilesEdit] = useState([]);
  const [banner, setBanner] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const [update, setUpdade] = useState(true);
  const changeUpdate = () => {
    setUpdade(!update);
  };

  useEffect(() => {
    api.get("/medicos").then((results) => {
      if (results) {
        setUsers(results.data);
      }
    });
  }, []);

  const handleSubmitAPI = async (val) => {
    const { ...values } = val;

    const formData = new FormData();
    formData.append("nome", values.nome);
    formData.append("descricao", values.descricao);
    formData.append("dataInicio", values.dataInicio);
    formData.append("dataFim", values.dataFim);
    formData.append("files", banner);

    for (var i = 0; i < links.length; i++) {
      formData.append("links", links[i]);
    }

    await api
      .post("/cursos", formData)
      .then(async (res) => {
        setIdCourse(res.data.id);

        await uploadFile(res.data.id, values.nome)
          .then((res) => {
            setTabActive(header[1]);
            // navigate("/courses", { replace: true });
          })
          .catch(({ response }) => {
            setApiResponse(response.data.erro);
          });
      })
      .catch(({ response }) => {
        setApiResponse(response.data.erro);
        enqueueSnackbar("Ocorreu um erro ao cadastrar curso", {
          variant: "error",
        });
      });
  };

  const addUsers = async (val) => {
    api
      .post(`/acessocurso/${idCourse}`, {
        usuarios: idUsers,
      })
      .then(async (res) => {
        navigate("/courses", { replace: true });
      })
      .catch(({ response }) => {
        setApiResponse(response.data.erro);
        enqueueSnackbar("Ocorreu um erro ao conceder acesso. Tente novamente", {
          variant: "error",
        });
      });
  };

  const Voltar = () => {
    setTabActive(header[0]);
  };

  const Cancelar = () => {
    navigate("/courses", { replace: true });
  };

  const CourseValidation = Yup.object().shape({
    nome: Yup.string().required("Campo Obrigatório"),
    descricao: Yup.string().required("Campo Obrigatório"),
    dataInicio: Yup.string().required("Campo Obrigatório"),
    dataFim: Yup.string().required("Campo Obrigatório"),
  });
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState([]);

  const saveFile = (e) => {
    // console.log(course.filesList[0]);
    console.log(e.target.files.length, files, fileName);
    const arquivos = [...e.target.files];

    arquivos.map((a) => {
      files.push(a);
      fileName.push(a.name);
      changeUpdate();
    });
  };

  const uploadFile = async (id, name) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("nameCourse", name);

      for (let i = 0; i < files.length; i++) {
        console.log(files[i]);
        formData.append("files", files[i]);
        // formData.append("fileName", files[i].name);
      }

      try {
        const res = await api.post(
          "/cursos/uploadFiles",

          formData
        );
        console.log(res);
      } catch (ex) {
        enqueueSnackbar(
          "Ocorreu um erro ao salvar arquivos. Faça um novo upload na área de visualização do curso.",
          {
            variant: "error",
          }
        );
        console.log(ex);
      }
    }
    return;
  };

  const addLink = (e) => {
    // console.log(files, fileName);
    if (e.length <= 0) return;

    const found = links?.find((item) => item === e);
    if (found) {
      enqueueSnackbar("Link semelhante já foi adicionado!", {
        variant: "error",
      });
      return;
    }

    links.push(e);
    changeUpdate();
  };

  function getFound(id) {
    const found = idUsers?.find((item) => parseInt(item) === id);
    if (found) {
      return true;
    }

    return false;
  }

  function handleAddUsers(id) {
    if (id === "all" && !selectTodos) {
      users.map((e) => {
        if (getFound(e.id)) return;
        idUsers.push(String(e.id));
        changeUpdate();
      });
      setTodos(true);
      return;
    }

    if (id === "all" && selectTodos) {
      setIdUsers([]);
      setTodos(false);
      changeUpdate();
      return;
    }

    const found = idUsers?.find((item) => item === id);
    if (found) {
      enqueueSnackbar("Usuário já selecionado", {
        variant: "error",
      });
      return;
    }

    idUsers.push(id);
    changeUpdate();
  }

  function returnName(id) {
    let name = users?.filter((item) => item?.id === parseInt(id));
    return name[0].nome;
  }

  const deleteFile = async (file) => {
    console.log(course.id, file);
    await api
      .delete(`/cursos/uploadFiles/${course.id}/${file}`)
      .then((response) => {
        console.log("teste", response);
        setFilesEdit((prevState) => prevState.filter((f) => f !== file));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (course.id) {
      setLinks(course.links);
      setFilesEdit(course.files);
    }
  }, [course]);

  const inputFile = document.querySelector("#picture__input");
  const pictureImage = document.querySelector(".picture__image");
  // const pictureImageTxt = "ESCOLHA O BANNER DO CURSO";
  // pictureImage?.innerHTML = pictureImageTxt;

  inputFile?.addEventListener("change", function (e) {
    const inputTarget = e.target;
    const file = inputTarget.files[0];

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function (e) {
        const readerTarget = e.target;

        const img = document.createElement("img");
        img.src = readerTarget.result;
        img.classList.add("picture__img");

        pictureImage.innerHTML = "";
        pictureImage.appendChild(img);
      });

      reader.readAsDataURL(file);
    } else {
      pictureImage.innerHTML = "ESCOLHA O BANNER DO CURSO";
    }
  });

  useEffect(() => {
    console.log("links", banner);
  }, [banner]);

  return (
    <Card>
      {render}
      <WizardBody>
        {tabActive === header[0] && (
          <>
            <label class="picture" htmlFor="picture__input" tabIndex="0">
              <span class="picture__image">ESCOLHA O BANNER DO CURSO</span>
            </label>
            <CardTitle>Informações Gerais</CardTitle>
            <Formik
              initialValues={course}
              validationSchema={CourseValidation}
              onSubmit={(values) => {
                setTabFilled(tabFilled + header[0]);
                // setTabActive(header[1]);
                handleSubmitAPI(values);
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Nome do Curso<Required>*</Required>
                      </Label>
                      <Input value={values.nome} name="nome" />
                      {errors.nome && touched.nome ? (
                        <Errors>{errors.nome}</Errors>
                      ) : null}
                    </InputContainer>

                    <Input
                      // value={values.arquivos}
                      name="picture__input"
                      id="picture__input"
                      type="file"
                      accept=".png, .jpeg, .jpg"
                      onChange={(e) => {
                        setBanner(e.target.files[0]);
                      }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Descrição do Curso<Required>*</Required>
                      </Label>
                      <Input
                        value={values.descricao}
                        name="descricao"
                        type="text"
                      />
                      {errors.descricao && touched.descricao ? (
                        <Errors>{errors.descricao}</Errors>
                      ) : null}
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Data início<Required>*</Required>
                      </Label>
                      <Input
                        value={values.dataInicio}
                        name="dataInicio"
                        type="date"
                      />
                      {errors.dataInicio && touched.dataInicio ? (
                        <Errors>{errors.dataInicio}</Errors>
                      ) : null}
                    </InputContainer>
                    <InputContainer>
                      <Label>
                        Data fim<Required>*</Required>
                      </Label>
                      <Input
                        value={values.dataFim}
                        name="dataFim"
                        type="date"
                      />
                      {errors.dataFim && touched.dataFim ? (
                        <Errors>{errors.dataFim}</Errors>
                      ) : null}
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Label>Arquivos </Label>

                      <Input
                        // value={values.arquivos}
                        name="arquivos[]"
                        type="file"
                        accept="application/pdf, .txt, .doc, docx, .csv, .xls, .xlc, .xlsx, .ppt, .pptx, .png, .jpeg, .jpg"
                        multiple
                        onChange={(e) => saveFile(e)}
                      />

                      {errors.arquivos && touched.arquivos ? (
                        <Errors>{errors.arquivos}</Errors>
                      ) : null}

                      {course.id
                        ? filesEdit.map((e) => (
                            <ListArquivos>
                              <ListText>{e}</ListText>
                              <ButtonX
                                type="text"
                                onClick={() => {
                                  deleteFile(e);
                                }}
                              >
                                <IoClose stroke={COLORS.white} size={26} />
                              </ButtonX>
                            </ListArquivos>
                          ))
                        : null}

                      {files.map((e) => (
                        <ListArquivos>
                          <ListText>{e.name}</ListText>
                          <ButtonX
                            onClick={() => {
                              setFiles((prevState) =>
                                prevState.filter((f) => f.name !== e.name)
                              );

                              setFileName((prevState) =>
                                prevState.filter((f) => f !== e.name)
                              );
                            }}
                          >
                            <IoClose stroke={COLORS.white} size={26} />
                          </ButtonX>
                        </ListArquivos>
                      ))}
                    </InputContainer>
                    <InputContainer>
                      <Label>Links</Label>
                      <InputLinks>
                        <Input value={values.link} name="link" type="text" />
                        {errors.link && touched.link ? (
                          <Errors>{errors.link}</Errors>
                        ) : null}
                        <Button
                          type="button"
                          style={{
                            background: `black`,
                            padding: 0,
                            width: "10%",
                            fontSize: "30px",
                          }}
                          onClick={(e) => addLink(values.link)}
                        >
                          +
                        </Button>
                      </InputLinks>
                      {links.map((e) => (
                        <ListArquivos>
                          <ListText>
                            {e.length > 20 ? e?.substring(0, 40) + "..." : e}
                          </ListText>
                          <ButtonX
                            onClick={() => {
                              console.log(e);
                              setLinks((prevState) =>
                                prevState.filter((f) => f !== e)
                              );
                            }}
                          >
                            <IoClose stroke={COLORS.white} size={26} />
                          </ButtonX>
                        </ListArquivos>
                      ))}
                    </InputContainer>
                  </InputGroup>

                  <Info>
                    Para prosseguir com o cadastro é necessário preencher o
                    formulário. Os campos com asterisco (*) são obrigatórios.
                  </Info>

                  <ButtonGroup>
                    <Button
                      onClick={Cancelar}
                      style={{
                        paddingInline: "40px",
                        background: COLORS.softWhite3,
                        color: COLORS.grey,
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button type="submit" style={{ paddingInline: "40px" }}>
                      Cadastrar <BsArrowRight fill={COLORS.white} size={20} />
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </>
        )}

        {tabActive === header[1] && (
          <>
            <CardTitle>
              Selecione os usuários que poderão acessar o curso
            </CardTitle>
            <Formik
              initialValues={{
                users: [],
              }}
              onSubmit={(values) => {
                setTabFilled(tabFilled + header[0]);
                // setTabActive(header[1]);
                addUsers(values);
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <InputGroup>
                    <InputContainer>
                      <Input
                        as="select"
                        onChange={(e) => {
                          // handleChange(e);
                          handleAddUsers(e.target.value);
                        }}
                        autoComplete="off"
                        name="user"
                        value={values.users}
                      >
                        <Option value>Selecione ...</Option>
                        <Option
                          key="all"
                          value="all"
                          style={{
                            color: getFound(4) ? "#B2BEB5" : "black",
                            fontWeight: 800,
                          }}
                        >
                          {selectTodos ? "Desmarcar todos" : "Marcar todos"}
                        </Option>
                        {users &&
                          users.map((el) => {
                            return (
                              <>
                                <Option
                                  key={el.id}
                                  value={el.id}
                                  style={{
                                    color: getFound(el.id)
                                      ? "#B2BEB5"
                                      : "black",
                                  }}
                                >
                                  {el.nome}
                                </Option>
                              </>
                            );
                          })}
                      </Input>
                      {errors.users && touched.users && (
                        <Errors>{errors.users}</Errors>
                      )}

                      <ListUsers>
                        {idUsers.map((e) => (
                          <ListArquivos>
                            <ListText>{returnName(e)}</ListText>
                            <ButtonX
                              onClick={() => {
                                setIdUsers((prevState) =>
                                  prevState.filter((f) => f !== e)
                                );
                              }}
                            >
                              <IoClose stroke={COLORS.white} size={26} />
                            </ButtonX>
                          </ListArquivos>
                        ))}
                      </ListUsers>
                    </InputContainer>
                  </InputGroup>

                  <ButtonGroup>
                    {/* <Button
                      onClick={Voltar}
                      style={{
                        paddingInline: "40px",
                        background: COLORS.softWhite3,
                        color: COLORS.grey,
                      }}
                    >
                      Voltar
                    </Button> */}
                    <Button type="submit" style={{ paddingInline: "40px" }}>
                      Finalizar <BsArrowRight fill={COLORS.white} size={20} />
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </>
        )}
      </WizardBody>
    </Card>
  );
}

// const label = name[0]?.length > 20 ? name[0]?.substring(0, 20) + '...' : name;

const Option = styled.option`
  padding: 15px;
  font-size: 18px;
  margin: 6px;
`;

const Errors = styled.span`
  display: block;
  font-size: 12px;
  margin: 6px;
  color: red;
`;

const Info = styled.span`
  display: block;
  font-size: 13px;
  margin-top: 20px;
  color: ${COLORS.grey};
  font-weight: 600;
`;

const Required = styled.span`
  color: red;
  font-weight: 600;
`;

const Card = styled.div`
  max-width: 1000px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
  border-radius: 4px;
`;

const CardTitle = styled.h4`
  margin-top: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${COLORS.blue};
`;

const InputGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 0;
  flex-grow: 1;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 100%;
  }
`;

const ListText = styled.p`
  padding: 0 20px;
  font-size: 15px;
`;

const ListArquivos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  background-color: #f4f5f5;
`;

const ListUsers = styled.div`
  padding: 15px;
  height: auto;
  flex-wrap: wrap;
  border: 1px solid ${COLORS.softWhite2};
  display: flex;
  justify-content: flex-start;
  gap: 8px;
`;

const InputLinks = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled(Field)`
  padding: 15px;
  border-radius: 4px;
  outline: none;
  width: 100%;
  border: 1px solid ${COLORS.softWhite2};
  max-length: 3;
  color: ${COLORS.dark};
`;

const ButtonGroup = styled.div`
  gap: 12px;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

export default Wizard;
