import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WizardBody from '../molecules/Wizard/WizardBody';
import useWizardHeader from '../molecules/Wizard/useWizardHeader';
import styled from 'styled-components';
import COLORS from '../../constants/colors';
import Button from '../atoms/Button';
import { BsArrowRight } from 'react-icons/bs';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import api from '../../services/api';
import SCREEN_SIZES from '../../constants/screenSizes';

function Wizard(props) {
  const header = ['Informações', 'Acesso'];
  const { tabActive, setTabActive, tabFilled, setTabFilled, render } =
    useWizardHeader(header);
  const [nome, setNome] = useState('');
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState('');

  const handleSubmitAPI = (val) => {
    const { ...values } = val;
    const data = api
      .post('/register', {
        nome,
        status: 1,
        ...values,
      })
      .then((res) => {
        navigate('/users', { replace: true });
      })
      .catch(({ response }) => {
        setApiResponse(response.data.erro);
      });
  };

  const Voltar = () => {
    setTabActive(header[0]);
  };
  
  const Cancelar = () => {
    navigate('/users', { replace: true });
  };

  const PersonalSchema = Yup.object().shape({
    completeName: Yup.string()
      .matches('[a-zA-Z]+', 'Nome não pode conter números')
      .min(4, 'Preencha seu nome Completo')
      .required('Campo Obrigatório'),
  });
  const UserSchema = Yup.object().shape({
    usuario: Yup.string()
      .email('Endereço de e-mail inválido')
      .min(4, 'Preencha seu nome Completo')
      .required('Campo Obrigatório'),
    senha: Yup.string().required(),
    profile: Yup.number().min(1, 'Escolha um perfil da lista'),
  });

  return (
    <Card>
      {render}
      <WizardBody>
        {tabActive === header[0] && (
          <>
            <CardTitle>Informações Pessoais</CardTitle>
            <Formik
              initialValues={{ completeName: nome }}
              validationSchema={PersonalSchema}
              onSubmit={(values) => {
                setNome(values.completeName);
                setTabFilled(tabFilled + header[0]);
                setTabActive(header[1]);
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Nome Completo<Required>*</Required>
                      </Label>
                      <Input value={values.completeName} name="completeName" />
                      {errors.completeName && touched.completeName ? (
                        <Errors>{errors.completeName}</Errors>
                      ) : null}
                    </InputContainer>
                  </InputGroup>

                  <Info>
                    Para prosseguir com o cadastro é necessário preencher o
                    formulário. Os campos com asterisco (*) são obrigatórios.
                  </Info>

                  <ButtonGroup>
                    <Button
                      onClick={Cancelar}
                      style={{
                        paddingInline: '40px',
                        background: COLORS.softWhite3,
                        color: COLORS.grey,
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button type="submit" style={{ paddingInline: '40px' }}>
                      Próximo <BsArrowRight fill={COLORS.white} size={20} />
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </>
        )}
        {tabActive === header[1] && (
          <>
            <CardTitle>Informações de Acesso</CardTitle>
            <Formik
              initialValues={{ usuario: '', senha: '', profile: '' }}
              validationSchema={UserSchema}
              onSubmit={(values) => {
                setTabFilled(tabFilled + header[1]);
                handleSubmitAPI(values);
              }}
            >
              {({ errors, touched, handleChange, values }) => (
                <Form onChange={() => setApiResponse('')}>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        E-mail<Required>*</Required>
                      </Label>
                      <Input
                        autoComplete="off"
                        name="usuario"
                        value={values.usuario}
                      />
                      {errors.usuario && touched.usuario ? (
                        <Errors>{errors.usuario}</Errors>
                      ) : null}
                      <Errors>{apiResponse}</Errors>
                    </InputContainer>
                    <InputContainer>
                      <Label>
                        Senha<Required>*</Required>
                      </Label>
                      <Input autoComplete="off" name="senha" type="password" />
                      {errors.senha && touched.senha ? (
                        <Errors>{errors.senha}</Errors>
                      ) : null}
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Perfil<Required>*</Required>
                      </Label>
                      <Input
                        as="select"
                        value={values.profile}
                        name="profile"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <Option value>Selecione o tipo de perfil</Option>
                        <Option value="1">Administrador</Option>
                        <Option value="2">Médico</Option>
                        <Option value="3">Empresa</Option>
                      </Input>
                      {errors.profile && touched.profile ? (
                        <Errors>{errors.profile}</Errors>
                      ) : null}
                    </InputContainer>
                  </InputGroup>
                  <Info>
                    Para prosseguir com o cadastro é necessário preencher o
                    formulário. Os campos com asterisco (*) são obrigatórios.
                  </Info>
                  <ButtonGroup>
                    <Button
                      onClick={Voltar}
                      style={{
                        paddingInline: '40px',
                        background: COLORS.softWhite3,
                        color: COLORS.grey,
                      }}
                    >
                      Voltar
                    </Button>
                    <Button type="submit" style={{ paddingInline: '40px' }}>
                      Próximo <BsArrowRight fill={COLORS.white} size={20} />
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </>
        )}
      </WizardBody>
    </Card>
  );
}

const Option = styled.option`
  padding: 15px;
`;

const Errors = styled.span`
  display: block;
  font-size: 12px;
  margin: 6px;
  color: red;
`;

const Info = styled.span`
  display: block;
  font-size: 13px;
  margin-top: 20px;
  color: ${COLORS.grey};
  font-weight: 600;
`;

const Required = styled.span`
  color: red;
  font-weight: 600;
`;

const Card = styled.div`
  max-width: 1000px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
  border-radius: 4px;
`;

const CardTitle = styled.h4`
  margin-top: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${COLORS.blue};
`;

const InputGroup = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 0;
  flex-grow: 1;
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled(Field)`
  padding: 15px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${COLORS.softWhite2};
  color: ${COLORS.dark};
`;

const ButtonGroup = styled.div`
  gap: 12px;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

export default Wizard;
