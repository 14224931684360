import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WizardBody from '../molecules/Wizard/WizardBody';
import useWizardHeader from '../molecules/Wizard/useWizardHeader';
import styled from 'styled-components';
import COLORS from '../../constants/colors';
import Button from '../atoms/Button';
import { BsArrowRight } from 'react-icons/bs';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import api from '../../services/api';
import SCREEN_SIZES from '../../constants/screenSizes';
import { administrationRoutes, cannabinoids, cannabinoidTypes, concentrationsOptions, extracts, mlIdsOptions, oralTypes, prescriptionTypes, topicTypes } from '../../utils/maps';
import Select from 'react-select';

function Wizard(props) {
  const header = ['Informações Gerais', 'Detalhes'];
  const { tabActive, setTabActive, tabFilled, setTabFilled, render } =
    useWizardHeader(header);
  const [empresa, setEmpresa] = useState('');
  const [nome, setNome] = useState('');
  const navigate = useNavigate();

  const handleSubmitAPI = (val) => {
    try {
      api
        .post('/medications', {
          name: nome,
          companyName: empresa,
          extractType: val.extract ? val.extract : null,
          prescriptionType: val.prescriptionType,
          primaryCannabinoids: val.priorityCannabinoid,
          secondaryCannabinoids: val.secondaryCannabinoid ? val.secondaryCannabinoid : null,
          administrationRoute: val.administrationRoute,
          mlIds: val.mlIds,
          
          ...(val.administrationRoute === 1 && {
              concentrations: val.concentrations ? val.concentrations : null,
              oralType: val.oralType,
          }),
          
          ...(val.administrationRoute === 2 && {
            topicalType: val.topicalType
          })

          
        })
        .then((res) => {
          navigate('/medicines', { replace: true });
        })
        .catch(({ response }) => {
          console.log(response.data.erro);
        });
    }catch (error) {
      console.log(error)
    }

    // const { ...values } = val;
    // const data = api
    //   .post('/medicamentos', {
    //     nome,
    //     empresa,
    //     ...values,
    //   })
    //   .then((res) => {
    //     navigate('/medicines', { replace: true });
    //   })
    //   .catch(({ response }) => {
    //     setApiResponse(response.data.erro);
    //   });
  };

  const Voltar = () => {
    setTabActive(header[0]);
  };
  const Cancelar = () => {
    navigate('/users', { replace: true });
  };

  const MedicineSchema = Yup.object().shape({
    company: Yup.string()
      .required('Campo Obrigatório'),
    medicineName: Yup.string()
      .required('Campo Obrigatório'),
  });
  const DetailsSchema = Yup.object().shape({
    extract: Yup.string()
      .min(1, 'Selecione um tipo de extrato')
      .nullable('Selecione um tipo de extrato da lista')
      .required('Selecione um tipo de extrato da lista'),
    mlIds: Yup.array()
      .min(1, 'Selecione um tipo de volume/massa')
      .nullable('Selecione um tipo de volume/massa')
      .required('Selecione um tipo de volume/massa'),
    prescriptionType: Yup.string()
      .min(1, 'Selecione um tipo de prescricão')
      .nullable('Selecione um tipo de prescricão')
      .required('Selecione um tipo de prescricão'),
    cannabinoidType: Yup.string()
    .when('extract', {
      is: 'ampliado',
      then: Yup.string().required('Selecione um tipo de canabinoide')
      .nullable('Selecione um tipo de canabinoide'),
    }),
    priorityCannabinoid: Yup.array()
      .min(1, 'Selecione um canabinoide prioritário')
      .required('Selecione um canabinoide prioritário da lista'),
    secondaryCannabinoid: Yup.number().nullable().optional(),
    administrationRoute: Yup.string()
    .min(1, 'Selecione uma via de administração da lista')
    .nullable('Selecione uma via de administração da lista')
      .required('Selecione uma via de administração da lista'),
    oralType: Yup.string().when('administrationRoute', {
      is: '1', // Oral route
      then: Yup.string().required('Selecione um tipo de administração oral').nullable('Selecione um tipo de administração oral'),
    }),
    topicType: Yup.string().when('administrationRoute', {
      is: '2', // Tópico route
      then: Yup.string()
      .min(1, 'Selecione um tipo de tópico')
      .nullable('Selecione um tipo de tópico')
      .required('Selecione um tipo de tópico'),
    }),
    concentrations: Yup.array().when('administrationRoute', {
      is: '1', // Oral route
      then: Yup.array()
      .min(1, 'Selecione pelo menos uma concentração')
      .nullable('Selecione pelo menos uma concentração')
      .required('Selecione pelo menos uma concentração'),
    }),
  });

  return (
    <Card>
      {render}
      <WizardBody>
        {tabActive === header[0] && (
          <>
            <CardTitle>Informações Gerais</CardTitle>
            <Formik
              initialValues={{ company: '', medicineName: '' }}
              validationSchema={MedicineSchema}
              onSubmit={(values) => {
                setEmpresa(values.company);
                setNome(values.medicineName);
                setTabFilled(tabFilled + header[0]);
                setTabActive(header[1]);
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Empresa<Required>*</Required>
                      </Label>
                      <Input value={values.company} name="company" />
                      {errors.company && touched.company ? (
                        <Errors>{errors.company}</Errors>
                      ) : null}
                    </InputContainer>
                    <InputContainer>
                      <Label>
                        Nome do Medicamento<Required>*</Required>
                      </Label>
                      <Input value={values.medicineName} name="medicineName" />
                      {errors.medicineName && touched.medicineName ? (
                        <Errors>{errors.medicineName}</Errors>
                      ) : null}
                    </InputContainer>
                  </InputGroup>

                  <Info>
                    Para prosseguir com o cadastro é necessário preencher o
                    formulário. Os campos com asterisco (*) são obrigatórios.
                  </Info>

                  <ButtonGroup>
                    <Button
                      onClick={Cancelar}
                      style={{
                        paddingInline: '40px',
                        background: COLORS.softWhite3,
                        color: COLORS.grey,
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button type="submit" style={{ paddingInline: '40px' }}>
                      Próximo <BsArrowRight fill={COLORS.white} size={20} />
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </>
        )}
        {tabActive === header[1] && (
          <>
            <CardTitle>Informações Complementares</CardTitle>
            <Formik
              initialValues={{ extract: undefined, prescriptionType: undefined, cannabinoidType: undefined, priorityCannabinoid: [], secondaryCannabinoid: undefined, administrationRoute: undefined, oralType: undefined, topicType: undefined, concentrations: [], mlIds: [] }}
              validationSchema={DetailsSchema}
              onSubmit={(values) => {
                console.log('teste');
                setTabFilled(tabFilled + header[1]);
                handleSubmitAPI(values);
              }}
            >
              {({ errors, touched, handleChange, values, setFieldValue }) => (
                <Form>
                  <InputGroup>
                  <InputContainer>
                    <Label>Qual o tipo de extrato?<Required>*</Required></Label>
                    
                    <Select
                      styles={customStyles}
                      options={extracts} // Assumindo que extracts tem formato [{ value: 'value', label: 'label' }]
                      name="extract"
                      value={extracts.find(option => option.value === values.extract)} // Seleciona o valor correspondente
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: "extract",
                            value: selectedOption ? selectedOption.value : null
                          }
                        });
                      }}
                      placeholder="Selecione o tipo de extrato"
                      isClearable // Permite limpar a seleção, se necessário
                    />

                    {errors.extract && touched.extract && <Errors>{errors.extract}</Errors>}
                  </InputContainer>
                    {values.extract === 'ampliado' ? (
                      <InputContainer>
                      <Label>Qual o tipo de canabinoide?<Required>*</Required></Label>
                      
                      <Select
                        styles={customStyles}
                        options={cannabinoidTypes} // Assumindo que cannabinoidTypes tem formato [{ value: 'value', label: 'label' }]
                        name="cannabinoidType"
                        value={cannabinoidTypes.find(option => option.value === values.cannabinoidType)} // Seleciona o valor correspondente
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: "cannabinoidType",
                              value: selectedOption ? selectedOption.value : null
                            }
                          });
                        }}
                        placeholder="Selecione o tipo de canabinoide"
                        isClearable // Permite limpar a seleção, se necessário
                      />
                    
                      {errors.cannabinoidType && touched.cannabinoidType && <Errors>{errors.cannabinoidType}</Errors>}
                    </InputContainer>
                    ) : <div style={{flex: 1}}/>}
                  </InputGroup>

                  <InputGroup>
                      <InputContainer>
                        <Label>Qual o canabinoide prioritário? <Required>*</Required></Label>
                        <Select
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            handleChange({
                              target: {
                                name: "priorityCannabinoid",
                                value: values.cannabinoidType === 'igualitário' ? selectedOptions.map(option => option.value) : [selectedOptions?.value]
                              }
                            });
                          }}
                          options={cannabinoids}
                          isMulti={values.cannabinoidType === 'igualitário' ? true : false}
                          name="priorityCannabinoid"
                          value={values.cannabinoidType === 'igualitário' ? cannabinoids.filter(option => values.priorityCannabinoid.includes(option.value)): values.priorityCannabinoid[0]?.value}
                          classNamePrefix="react-select"
                          placeholder="Selecione o canabinoide prioritário"
                        />
                        {errors.priorityCannabinoid && touched.priorityCannabinoid && <Errors>{errors.priorityCannabinoid}</Errors>}
                      </InputContainer>
                      
                      {!!values.extract && values.extract !== 'isolado' && values.cannabinoidType !== 'igualitário' ? (
                        <InputContainer>
                        <Label>Possui canabinoide secundário, qual?</Label>
                        
                        <Select
                          styles={customStyles}
                          options={cannabinoids} // Assumindo que cannabinoids tem formato [{ value: 'value', label: 'label' }]
                          name="secondaryCannabinoid"
                          value={cannabinoids.find(option => option.value === values.secondaryCannabinoid)} // Seleciona o valor correspondente
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: "secondaryCannabinoid",
                                value: selectedOption ? selectedOption.value : null
                              }
                            });
                          }}
                          placeholder="Selecione o canabinoide secundário"
                          isClearable // Permite limpar a seleção, se necessário
                        />
                        
                        {errors.secondaryCannabinoid && touched.secondaryCannabinoid && <Errors>{errors.secondaryCannabinoid}</Errors>}
                        </InputContainer>
                      ): <div style={{flex: 1}}/>}
                  </InputGroup>

                  <InputGroup>
                    <InputContainer>
                      <Label>Qual a via de administração? <Required>*</Required></Label>

                      <Select
                        styles={customStyles}
                        options={administrationRoutes} // Assumindo que administrationRoutes tem formato [{ value: 'value', label: 'label' }]
                        name="administrationRoute"
                        value={administrationRoutes.find(option => option.value === values.administrationRoute)} // Seleciona o valor correspondente
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: "administrationRoute",
                              value: selectedOption ? selectedOption.value : null,
                            },
                          });
                          // Limpar os campos relacionados
                          setFieldValue('oralType', undefined);
                          setFieldValue('topicType', undefined);
                        }}
                        placeholder="Selecione a via de administração"
                        isClearable // Permite limpar a seleção, se necessário
                      />

                      {errors.administrationRoute && touched.administrationRoute && <Errors>{errors.administrationRoute}</Errors>}
                    </InputContainer>
                    {values.administrationRoute === 1 && (
                      <InputContainer>
                      <Label>Qual o tipo de administração Oral? <Required>*</Required></Label>
                    
                      <Select
                        styles={customStyles}
                        options={oralTypes} 
                        name="oralType"
                        value={oralTypes.find(option => option.value === values.oralType)} // Seleciona o valor correspondente
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: "oralType",
                              value: selectedOption ? selectedOption.value : null
                            }
                          });
                        }}
                        placeholder="Selecione o tipo de administração Oral"
                        isClearable // Permite limpar a seleção, se necessário
                      />
                    
                      {errors.oralType && touched.oralType && <Errors>{errors.oralType}</Errors>}
                    </InputContainer>
                    )}
                    {values.administrationRoute === 2 && (
                      <InputContainer>
                      <Label>Qual o tipo do Tópico? <Required>*</Required></Label>
                    
                      <Select
                        styles={customStyles} 
                        options={topicTypes} 
                        name="topicType"
                        value={topicTypes.find(option => option.value === values.topicType)} // Seleciona o valor correspondente
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: "topicType",
                              value: selectedOption ? selectedOption.value : null
                            }
                          });
                        }}
                        placeholder="Selecione o tipo do Tópico"
                        isClearable // Permite limpar a seleção, se necessário
                      />
                    
                      {errors.topicType && touched.topicType && <Errors>{errors.topicType}</Errors>}
                    </InputContainer>
                    )}
                    {![1,2].includes(values.administrationRoute) && (
                      <div style={{flex: 1}}/>
                    )}
                  </InputGroup>

                  <InputGroup>
                    <InputContainer>
                      <Label>Quais o tipo de prescrição? <Required>*</Required></Label>
                      <Select
                         styles={customStyles} 
                         options={prescriptionTypes} 
                         name="prescriptionType"
                         value={prescriptionTypes.find(option => option.value === values.prescriptionType)} 
                         onChange={(selectedOption) => {
                           handleChange({
                             target: {
                               name: "prescriptionType",
                               value: selectedOption ? selectedOption.value : null
                             }
                           });
                         }}
                         placeholder="Selecione o tipo de prescrição"
                         isClearable 
                      />
                      {errors.prescriptionType && touched.prescriptionType && <Errors>{errors.prescriptionType}</Errors>}
                    </InputContainer>

                    { values.administrationRoute === 1 ? (
                      <InputContainer>
                        <Label>Quais são as concentrações disponíveis? <Required>*</Required></Label>
                        <Select
                          styles={customStyles} 
                          onChange={(selectedOptions) => {
                            handleChange({
                              target: {
                                name: "concentrations",
                                value: selectedOptions.map(option => option.value),
                              },
                            });
                          }}
                          options={concentrationsOptions}
                          isMulti
                          name="concentrations"
                          value={concentrationsOptions.filter(option => values.concentrations.includes(option.value))}
                          classNamePrefix="react-select"
                          placeholder="Selecione as concentrações"
                        />
                        {errors.concentrations && touched.concentrations && <Errors>{errors.concentrations}</Errors>}
                      </InputContainer>
                      )
                    : <div style={{flex: 1}}/>}
                  </InputGroup>

                  <InputGroup>
                    <InputContainer>
                          <Label>Quais são as volumes/massas disponíveis? <Required>*</Required></Label>
                          <Select
                            styles={customStyles} 
                            onChange={(selectedOptions) => {
                              handleChange({
                                target: {
                                  name: "mlIds",
                                  value: selectedOptions.map(option => option.value),
                                },
                              });
                            }}
                            options={mlIdsOptions}
                            isMulti
                            name="mlIds"
                            value={mlIdsOptions.filter(option => values.mlIds.includes(option.value))}
                            classNamePrefix="react-select"
                            placeholder="Selecione o(s) volumes/massas"
                          />
                          {errors.mlIds && touched.mlIds && <Errors>{errors.mlIds}</Errors>}
                    </InputContainer>
                    <div style={{flex: 1}}/>
                  </InputGroup>

                  <Info>
                    Para prosseguir com o cadastro é necessário preencher o
                    formulário. Os campos com asterisco (*) são obrigatórios.
                  </Info>
                  <ButtonGroup>
                    <Button
                      onClick={Voltar}
                      style={{
                        paddingInline: '40px',
                        background: COLORS.softWhite3,
                        color: COLORS.grey,
                      }}
                    >
                      Voltar
                    </Button>
                    <Button type="submit" style={{ paddingInline: '40px' }}>
                      Cadastrar <BsArrowRight fill={COLORS.white} size={20} />
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </>
        )}
      </WizardBody>
    </Card>
  );
}

// Definir os estilos personalizados
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #ccc', // Exemplo de borda similar ao input
    boxShadow: state.isFocused ? '0 0 0 1px #blue' : null, // Adiciona efeito de foco
    borderRadius: '4px', // Ajuste de borda arredondada
    padding: '5px', // Ajuste de padding interno
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#000' : '#333',
    padding: '10px', // Aumenta o espaço nas opções
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: '3px',
    padding: '3px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#333', // Cor do texto dos itens selecionados
  }),

}

const Option = styled.option`
  padding: 15px;
`;

const Errors = styled.span`
  display: block;
  font-size: 12px;
  margin: 6px;
  color: red;
`;

const Info = styled.span`
  display: block;
  font-size: 13px;
  margin-top: 20px;
  color: ${COLORS.grey};
  font-weight: 600;
`;

const Required = styled.span`
  color: red;
  font-weight: 600;
`;

const Card = styled.div`
  max-width: 1000px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
  border-radius: 4px;
`;

const CardTitle = styled.h4`
  margin-top: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${COLORS.blue};
`;

const InputGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 0;
  flex-grow: 1;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
      width: 100%;
  }
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled(Field)`
  padding: 15px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${COLORS.softWhite2};
  color: ${COLORS.dark};
`;

const ButtonGroup = styled.div`
  gap: 12px;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

export default Wizard;
