import React from 'react';
import styled from 'styled-components';
import Logo from '../components/atoms/Logo';
import FirstLoginForm from '../components/molecules/FirstLoginForm';
import SCREEN_SIZES from '../constants/screenSizes';

const FirstLogin = () => {
  return (
    <LoginContainer>
      <LeftScreen>
        <LogoImage />
        <FirstLoginForm />
      </LeftScreen>
      <RightScreen />
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media (max-width: 600px) {
  }
`;

const LeftScreen = styled.div`
  background: #fff;
  width: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 40px;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    width: 100%;
  }
`;

const RightScreen = styled.div`
  background-image: url(./images/cannabis.png);
  background-size: cover;
  background-position: center;
  width: 0;
  flex-grow: 1;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    display: none;
  }
`;

const LogoImage = styled(Logo)`
  position: absolute;
  top: 70px;
  left: 40px;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    position: relative;
    top: -20px;
    left: 0;
  }
`;

export default FirstLogin;
