import React from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import COLORS from '../../constants/colors';
import { IoMdLogOut } from "react-icons/io";

function Logout() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const signOut = () => {
        cookies.remove('sechat-token')
        cookies.remove('nome')
        cookies.remove('id')
        cookies.remove('first_access')
        cookies.remove('_ga_EG6SMZTGWQ')
        cookies.remove('_ga')
        navigate('/login', { replace: true });
    };
    return (
        <Sair onClick={signOut}>Sair <IoMdLogOut fill={COLORS.white} style={{ marginTop: '3px' }} /></Sair>
    )
}

const Sair = styled.a`
  color: ${COLORS.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
`;
export default Logout