import React from "react";
import styled from "styled-components";
import COLORS from "../../../constants/colors";
import { IoClose } from "react-icons/io5";
import Button from "../../atoms/Button";
import api from "../../../services/api";

function ModalDelete({
  children,
  active,
  setModalConfirm,
  title,
  subtitle,
  userId,
  userStatus,
  type,
  onClick,
  isClick,
  courseId,
  accessId,
}) {
  const handleDelete = (userId, userStatus) => {
    if (type === "medicamentos") {
      api.delete(`/medicamentos/${userId}`).then(() => {
        window.location.reload(false);
      });
    } else if (type === "activate") {
      console.log(userStatus);
      api
        .post("/ativar", {
          status: userStatus === false ? 0 : 1,
          id: userId,
        })
        .then((res) => {
          window.location.reload(false);
        })
        .catch((response) => {
          console.log(response);
        });
    } else if (type === "activate_access") {
      console.log(courseId, userId);
      api
        .post(`/acessocurso/${courseId}`, { usuarios: [userId] })
        .then((res) => {
          window.location.reload(false);
        })
        .catch((response) => {
          console.log(response);
        });
    } else if (type === "inactivate_access") {
      console.log(accessId, userId, courseId);
      api
        .delete(`/acessocurso/${accessId}`)
        .then((res) => {
          window.location.reload(false);
        })
        .catch((response) => {
          console.log(response);
        });
    } else if (type === "cursos") {
      console.log(`Id do curso aqui no modalDelete ${courseId}`);
      api.delete(`/cursos/${courseId}`).then(() => {
        window.location.reload(false);
      });
    } else {
      api.delete(`/users/${userId}`).then(() => {
        window.location.reload(false);
      });
    }
    setModalConfirm(false);
    // window.location.reload(false);
  };

  return (
    <>
      <Wrapper />
      <Container active={active}>
        <Close>
          <IoClose
            onClick={() => setModalConfirm(false)}
            fill={COLORS.grey}
            size={30}
            style={{ cursor: "pointer", float: "right" }}
          />
        </Close>
        <ModalTitle>{title}</ModalTitle>
        <ModalSubtitle>{subtitle}</ModalSubtitle>
        {children}
        <ActionGroup>
          <Button
            onClick={() => setModalConfirm(false)}
            style={{
              padding: "12px 24px",
              background: COLORS.softWhite2,
              color: COLORS.grey,
            }}
          >
            Cancelar
          </Button>
          {isClick ? (
            <Button onClick={onClick} style={{ padding: "12px 24px" }}>
              Confirmar
            </Button>
          ) : (
            <Button
              onClick={() => handleDelete(userId, userStatus)}
              style={{ padding: "12px 24px" }}
            >
              Confirmar
            </Button>
          )}
        </ActionGroup>
      </Container>
    </>
  );
}

const ActionGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 20px;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #00000099;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const Close = styled.div``;

const Container = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  position: absolute;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${COLORS.white};
  border-radius: 0.6rem;
  border: 1px solid ${COLORS.softWhite};
  min-height: 40vh;
  min-width: 30vw;
  width: 550px;
  max-width: 80vw;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
`;

const ModalTitle = styled.h4`
  font-weight: 600;
  font-size: 1.3rem;
  text-align: center;
`;

const ModalSubtitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 24px;
`;

export default ModalDelete;
