import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../atoms/Button";
import LoginInput from "../atoms/LoginInput";
import Cookies from "universal-cookie";
import { Rings } from "react-loader-spinner";
import { usePermify } from "@permify/react-role";
import SCREEN_SIZES from "../../constants/screenSizes";
import api from "../../services/api";

const FirstLoginForm = () => {
  const navigate = useNavigate();
  const [senha, setSenha] = useState("");
  const [confSenha, setConfSenha] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();
  const { setUser } = usePermify();

  const profileToRolesMap = {
    1: ['Administrador'],
    2: ['Medico'],
    3: ['Empresa'], 
  };

  const Login = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (senha !== confSenha) {
      setLoading(false);
      return setMsg("As senhas não são iguais!");
    } else {
      try {
        await api.post("/first_pass", {
            user_id: Number(cookies.get("id")),
            password: senha,
          })
          .then((res) => {
            setUser({
              id: res.data.newUser.id,
              roles: profileToRolesMap[res.data.newUser.profile] || []
            });

            cookies.set("first_access", res.data.newUser.first_access, {
              path: "/",
              sameSite: "none",
              secure: true,
            });

            console.log(cookies.get("first_access"), res.data);
          })
          .then(() => {
            console.log("Entrou");

            navigate("/", { replace: true });
          });
      } catch (error) {
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            setMsg("Usuário ou senha inválidos.");
          } else if (error.response.status === 403) {
            console.log(error);
            setMsg(error.response.data.error);
          } else {
            setMsg("Ocorreu um erro. Por favor tente novamente mais tarde.");
          }
        }
        setLoading(false);
      }
    }
  };

  return (
    <DivContainer onSubmit={Login}>
      <TitleContainer>
        <Title>Olá, {cookies.get("nome")}.</Title>
        <Subtitle>
          Antes de começar precisamos que você informe uma nova senha para a sua
          conta!
        </Subtitle>
        <Errors>{msg}</Errors>
      </TitleContainer>
      <LoginInput
        value={senha}
        onChange={(e) => setSenha(e.target.value)}
        type="password"
        labeltext="Nova Senha"
      />
      <LoginInput
        value={confSenha}
        onChange={(e) => setConfSenha(e.target.value)}
        type="password"
        labeltext="Confirme sua Senha"
      />
      <Button style={{ marginTop: "16px", width: "100%" }}>
        {!loading && "Atualizar Senha"}
        {loading && (
          <Rings height="35" color="white" ariaLabel="loading" wrapperStyle />
        )}
      </Button>
    </DivContainer>
  );
};

const RegisterLink = styled.a`
  display: block;
  font-size: 14px;
  font-family: "Public Sans", sans-serif;
  color: #7c94a4;
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  cursor: pointer;
`;

const Errors = styled.span`
  display: block;
  font-size: 12px;
  margin: 6px;
  color: red;
`;

const DivContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
`;

const TitleContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-bottom: 0px;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 0;
  font-weight: 600;
  color: #4b4b4b;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.p`
  margin: 0;
  padding: 0;
  color: #7c94a4;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    font-size: 0.9rem;
  }
`;

export default FirstLoginForm;
