import React from 'react';
import styled from 'styled-components';

const Logo = (props) => {
  return <LogoImg className={props.className} style={props.style} src="./images/sechatLogo.svg" />;
};

const LogoImg = styled.img`
  width: auto;
  height: 44px;
`;
export default Logo;
