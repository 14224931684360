import React from 'react';
import TableSearchHistory from '../components/molecules/TableSearchHistory';
import Root from '../components/organisms/Root';
import { HasAccess } from "@permify/react-role";

function SearchHistory() {
  return (
    <Root active={'search'}>
      <HasAccess
        roles={["Administrador", "Medico"]}
        renderAuthFailed={<p>Erro: Você não tem permissão para acessar essa tela.</p>}
      >
        <TableSearchHistory tableName={'Histórico de pesquisa'} />
      </HasAccess >
    </Root>

  );
}


export default SearchHistory;
