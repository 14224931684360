import React, { useState, useEffect } from 'react';
import questionsData from './questions.json'; // Supondo que você tenha um arquivo questions.json
import styled from 'styled-components';
import COLORS from '../../constants/colors'; // Certifique-se de ter os mesmos valores de cores definidos aqui
import { BsArrowRight } from 'react-icons/bs';
import SCREEN_SIZES from '../../constants/screenSizes';
import Button from "../atoms/Button";
import TableMedicamentos from '../molecules/TableMedicamentos';

const FilterFlow = () => {
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [answers, setAnswers] = useState({});
  const [questionHistory, setQuestionHistory] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Start with the first question
    setCurrentQuestionId(questionsData.questions[0].id);
  }, []);

  const handleAnswerChange = (questionId, answer) => {
    setAnswers(prevAnswers => ({ ...prevAnswers, [questionId]: answer }));
    setErrorMessage(''); // Clear any existing error message when a new selection is made
  };

  const handleCheckboxChange = (questionId, value) => {
    const selectedAnswers = answers[questionId] || [];
    if (selectedAnswers.includes(value)) {
      handleAnswerChange(questionId, selectedAnswers.filter((item) => item !== value));
    } else {
      handleAnswerChange(questionId, [...selectedAnswers, value]);
    }
  };

  const handleNext = () => {
    const currentQuestion = questionsData.questions.find(q => q.id === currentQuestionId);
    const answer = answers[currentQuestionId];

    // Verificação especial para a pergunta com múltiplas seleções (q8)
    if (currentQuestion.id === 'q8' && (!answer || answer.length !== 2)) {
      setErrorMessage(
        answer?.length < 2 ? 'Selecione duas opções.' : 'Selecione somente duas opções.'
      );
      return; // Não permitir avançar se a condição não for atendida
    }

    // Verificar se a pergunta obrigatória foi respondida
    if (!answer || (Array.isArray(answer) && answer.length === 0)) {
      setErrorMessage('Por favor, selecione uma opção antes de prosseguir.');
      return;
    }

    // Determine the next question based on the answer
    const nextQuestionId = currentQuestion.next[answer] || currentQuestion.next.default || null;

    if (nextQuestionId) {
      setQuestionHistory(prevHistory => [...prevHistory, currentQuestionId]); // Add current question to history
      setCurrentQuestionId(nextQuestionId);
    } else {
      // No more questions, end the flow
      setCurrentQuestionId(null);
    }
  };

  const handleBack = () => {
    if (questionHistory.length > 0) {
      const previousQuestionId = questionHistory[questionHistory.length - 1];

       // Remove a resposta da pergunta atual antes de voltar
      setAnswers(prevAnswers => {
        const updatedAnswers = { ...prevAnswers };
        delete updatedAnswers[currentQuestionId];
        return updatedAnswers;
      });

      setQuestionHistory(prevHistory => prevHistory.slice(0, -1)); // Remove the last question from history
      setCurrentQuestionId(previousQuestionId);
    }
  };

  const currentQuestion = questionsData.questions.find(q => q.id === currentQuestionId);

  return (
    <Container>
      {currentQuestion ? (
        <QuestionCard key={currentQuestion.id}>
        <Info>
          Para prosseguir com a sugestão, é necessário preencher o
          formulário. Os campos com asterisco (*) são
          obrigatórios.
        </Info>
          <QuestionTitle>{currentQuestion.question}</QuestionTitle>
          {currentQuestion.id === 'q8' ? (
            <OptionsContainer>
              {currentQuestion.options.map(option => (
                <OptionLabel key={option.value}>
                  <OptionInput
                    type="checkbox"
                    value={option.value}
                    checked={answers[currentQuestion.id]?.includes(option.value) || false}
                    onChange={() => handleCheckboxChange(currentQuestion.id, option.value)}
                  />
                  {option.label}
                </OptionLabel>
              ))}
            </OptionsContainer>
          ) : (
            <Select
              onChange={(e) => handleAnswerChange(currentQuestion.id, e.target.value)}
              value={answers[currentQuestion.id] || ""}
            >
              <SelectOption value="" disabled>Selecione uma opção</SelectOption>
              {currentQuestion.options.map(option => (
                <SelectOption key={option.value} value={option.value}>
                  {option.label}
                </SelectOption>
              ))}
            </Select>
          )}

          <ButtonGroup>
            <Button
              onClick={handleBack}
              disabled={questionHistory.length === 0}
              style={{
                paddingInline: "40px",
                background: COLORS.grey,
                color: COLORS.white,
              }}
            >
              Voltar
            </Button>
            <Button onClick={handleNext} disabled={!answers[currentQuestion.id]} style={{ paddingInline: "40px" }}>
              Próximo <BsArrowRight fill={COLORS.white} size={20} />
            </Button>
          </ButtonGroup>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        </QuestionCard>
      ) : (
        <TableMedicamentos
          filter={false}
          tableName={"Medicações Sugeridas"}
          extractType={answers.q1}
          primaryCannabinoid={answers.q8 || [answers.q2 || answers.q9]}
          secondaryCannabinoid={answers.q3 === "SIM" && answers.q4}
          administrationRoute={answers.q5 || answers['q5-1']}
          concentration={answers.q6_1}
          oralType={answers.q6}
          topicalType={answers.q7}
        />
      )}
    </Container>
  );
};


// Styled Components


const Info = styled.span`
  display: block;
  font-size: 13px;
  margin-top: 20px;
  color: ${COLORS.grey};
  font-weight: 600;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 20px;
`;

const QuestionCard = styled.div`
  max-width: 800px;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
  border-radius: 4px;
  padding: 20px;
  background-color: ${COLORS.white};
`;

const QuestionTitle = styled.h2`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${COLORS.blue};
`;

const OptionsContainer = styled.div`
  margin: 20px 0;
`;

const OptionLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  color: ${COLORS.dark};
  cursor: pointer;

  input[type="checkbox"]:hover {
    cursor: pointer;
  }
`;


const OptionInput = styled.input`
  margin-right: 10px;
  cursor: pointer;
`;

const Select = styled.select`
  padding: 15px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${COLORS.softWhite2};
  color: ${COLORS.dark};
  width: 100%;
  cursor: pointer;
`;

const SelectOption = styled.option`
  padding: 15px;
`;

const ButtonGroup = styled.div`
  gap: 12px;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
`;

const ErrorText = styled.p`
color: red; 
font-size: 14px; 
margin-top: 10px;
`;

const EndText = styled.p`font-size: 18px; font-weight: bold; color: ${COLORS.dark};`;

export default FilterFlow;