import React, { useEffect, useState } from "react";
import Root from "../../components/organisms/Root";
import { HasAccess } from "@permify/react-role";
import ViewAccessCourse from "../../components/molecules/courses/ViewAccessCourse";
import { useLocation, useNavigate } from "react-router";
import api from "../../services/api";

function ViewUsersPermissions(props) {
  const location = useLocation();
  const navigate = useNavigate();
  let cursoId = location.state?.id;
  const [course, setCourse] = useState("");

  const getcurso = async () => {
    try {
      const { data: cursos } = await api.get(`/cursos/${cursoId}`);
      setCourse(cursos);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    if (!cursoId) {
      navigate("/courses");
    }
    getcurso();
  }, []);

  return (
    <Root active={"courses"}>
      <HasAccess
        roles={["Administrador"]}
        renderAuthFailed={
          <p>Erro: Você não tem permissão para acessar essa tela.</p>
        }
      >
        <ViewAccessCourse
          tableName={`Acesso dos Usuários ao curso '${course.nome}'`}
          cursoId={cursoId}
        />
      </HasAccess>
    </Root>
  );
}

export default ViewUsersPermissions;
