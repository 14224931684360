import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import WizardBody from "../molecules/Wizard/WizardBody";
import useWizardHeader from "../molecules/Wizard/useWizardHeader";
import styled from "styled-components";
import COLORS from "../../constants/colors";
import Button from "../atoms/Button";
import { BsArrowRight } from "react-icons/bs";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import api from "../../services/api";
import SCREEN_SIZES from "../../constants/screenSizes";
import Cookies from "universal-cookie";
import ButtonX from "../atoms/ButtonX";
import { useSnackbar } from "notistack";
import { RotatingLines } from "react-loader-spinner";
import { IoClose } from "react-icons/io5";
import "../../styles/style.css";

function Wizard({ courseId }) {
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState("");
  const [idCourse, setIdCourse] = useState("");
  const [filesEdit, setFilesEdit] = useState([]);
  const [linksEdite, setLinksEdite] = useState([]);

  const [course, setCourse] = useState("");
  const [links, setLinks] = useState([]);

  const [banner, setBanner] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadLink, setLoadLink] = useState(false);

  const inputFile = document.querySelector("#picture__input");
  const pictureImage = document.querySelector(".picture__image");

  const { enqueueSnackbar } = useSnackbar();

  const [update, setUpdade] = useState(true);
  const changeUpdate = () => {
    setUpdade(!update);
  };

  const handleSubmitAPI = async (val) => {
    const { ...values } = val;

    const formData = new FormData();
    formData.append("nome", values.nome);
    formData.append("descricao", values.descricao);
    formData.append("dataInicio", values.dataInicio);
    formData.append("dataFim", values.dataFim);
    formData.append("files", banner);

    for (var i = 0; i < links.length; i++) {
      formData.append("links", links[i]);
    }

    await api
      .put(`/cursos/${courseId}`, formData)
      .then(async (res) => {
        await uploadFile(courseId, values.nome)
          .then((res) => {
            navigate("/courses", { replace: true });
          })
          .catch(({ response }) => {
            setApiResponse(response.data.erro);
          });
      })
      .catch(({ response }) => {
        setApiResponse(response.data.erro);
        enqueueSnackbar("Ocorreu um erro ao editar curso", {
          variant: "error",
        });
      });
  };

  const Cancelar = () => {
    navigate("/courses", { replace: true });
  };

  const CourseValidation = Yup.object().shape({
    nome: Yup.string().required("Campo Obrigatório"),
    descricao: Yup.string().required("Campo Obrigatório"),
    dataInicio: Yup.string().required("Campo Obrigatório"),
    dataFim: Yup.string().required("Campo Obrigatório"),
  });
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState([]);

  const saveFile = (e) => {
    // console.log(course.filesList[0]);
    const arquivos = [...e.target.files];

    arquivos.map((a) => {
      files.push(a);
      fileName.push(a.name);
      changeUpdate();
    });
  };

  const uploadFile = async (id, name) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("nameCourse", name);

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
        // formData.append("fileName", files[i].name);
      }

      try {
        const res = await api.post(
          "/cursos/uploadFiles",

          formData
        );
      } catch (ex) {
        enqueueSnackbar(
          "Ocorreu um erro ao salvar arquivos. Faça um novo upload na área de visualização do curso.",
          {
            variant: "error",
          }
        );
        console.log(ex);
      }
    }
    return;
  };

  const addLink = (e) => {
    if (e.length <= 0) return;

    const found = links?.find((item) => item === e);
    const found1 = linksEdite?.find((item) => item === e);
    if (found || found1) {
      enqueueSnackbar("Link semelhante já foi adicionado!", {
        variant: "error",
      });
      return;
    }

    links.push(e);
    changeUpdate();
  };

  const deleteFile = async (file) => {
    const response = await api
      .put(`/cursos/uploadFiles/${course.id}`, {
        file,
      })
      .then((response) => {
        console.log("teste", response);
        setFilesEdit((prevState) => prevState.filter((f) => f !== file));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteLink = async (link) => {
    await api
      .put(`/linkscursos/${courseId}`, { link })
      .then((response) => {
        if (response.status === 200) {
          setLinksEdite((prevState) => prevState.filter((f) => f !== link));
        } else {
          enqueueSnackbar("Ocorreu um erro ao deletar link. Tente novamente.", {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar("Ocorreu um erro ao deletar link. Tente novamente.", {
          variant: "error",
        });
      });
  };

  const getCurso = async () => {
    setLoading(true);
    setLoadLink(true);
    try {
      const { data: cursos } = await api.get(`/cursos/${courseId}`);
      setCourse(cursos.result);

      if (cursos.banner) {
        await api
          .get(
            `/cursos/uploadFiles/donwloadBanner/${courseId}/${cursos.banner}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            const file = new Blob([response.data], {
              type: response.data.type,
            });

            const fileURL = URL.createObjectURL(file);
            const img = document.createElement("img");
            img.src = fileURL;
            img.classList.add("picture__img");

            pictureImage?.appendChild(img);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      await api
        .get(`/linkscursos/${courseId}`)
        .then(async (response) => {
          if (response.data.length > 0) {
            const lin = [];
            response.data.map((l) => {
              lin.push(l.descricao);
            });
            setLinksEdite(lin);
          }

          await api
            .get(`/cursos/uploadFiles/${courseId}`, {})
            .then(async (response) => {
              const file = [];
              for (let i = 0; i < response.data.length; i++) {
                file.push(response.data[i]);
              }
              setFilesEdit(file);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });

      setLoadLink(false);
      setLoading(false);
    } catch (e) {
      alert(e);
    }
  };

  const downloadFile = async (file) => {
    await api
      .get(`/cursos/uploadFiles/donwloadFiles/${courseId}/${file}`, {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], {
          type: response.data.type,
        });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCurso();
  }, [courseId, pictureImage]);

  inputFile?.addEventListener("change", function (e) {
    const inputTarget = e.target;
    const file = inputTarget.files[0];

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function (e) {
        const readerTarget = e.target;

        const img = document.createElement("img");
        img.src = readerTarget.result;
        img.classList.add("picture__img");

        pictureImage.innerHTML = "";
        pictureImage.appendChild(img);
      });

      reader.readAsDataURL(file);
    } else {
      pictureImage.innerHTML = "ESCOLHA O BANNER DO CURSO";
    }
  });

  return (
    <Card>
      {course && (
        <WizardBody>
          <label class="picture" htmlFor="picture__input" tabIndex="0">
            <span class="picture__image"></span>
          </label>
          <CardTitle>{`Editar curso: ${course.nome}`}</CardTitle>
          <Formik
            initialValues={course}
            validationSchema={CourseValidation}
            onSubmit={(values) => handleSubmitAPI(values)}
          >
            {({ values, errors, touched }) => {
              //  console.log(values);
              return (
                <Form>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Nome do Curso<Required>*</Required>
                      </Label>
                      <Input value={values.nome} name="nome" />
                      {errors.nome && touched.nome ? (
                        <Errors>{errors.nome}</Errors>
                      ) : null}
                    </InputContainer>

                    <Input
                      // value={values.arquivos}
                      name="picture__input"
                      id="picture__input"
                      type="file"
                      accept=".png, .jpeg, .jpg"
                      onChange={(e) => {
                        setBanner(e.target.files[0]);
                      }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Descrição do Curso<Required>*</Required>
                      </Label>
                      <Input
                        value={values.descricao}
                        name="descricao"
                        type="text"
                      />
                      {errors.descricao && touched.descricao ? (
                        <Errors>{errors.descricao}</Errors>
                      ) : null}
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Data início<Required>*</Required>
                      </Label>
                      <Input
                        value={values.dataInicio}
                        name="dataInicio"
                        type="date"
                      />
                      {errors.dataInicio && touched.dataInicio ? (
                        <Errors>{errors.dataInicio}</Errors>
                      ) : null}
                    </InputContainer>
                    <InputContainer>
                      <Label>
                        Data fim<Required>*</Required>
                      </Label>
                      <Input
                        value={values.dataFim}
                        name="dataFim"
                        type="date"
                      />
                      {errors.dataFim && touched.dataFim ? (
                        <Errors>{errors.dataFim}</Errors>
                      ) : null}
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Label>Arquivos </Label>

                      <Input
                        // value={values.arquivos}
                        name="arquivos[]"
                        type="file"
                        accept="application/pdf, .txt, .doc, docx, .csv, .xls, .xlc, .xlsx, .ppt, .pptx, .png, .jpeg, .jpg"
                        multiple
                        onChange={(e) => saveFile(e)}
                      />

                      {errors.arquivos && touched.arquivos ? (
                        <Errors>{errors.arquivos}</Errors>
                      ) : null}

                      {loading ? (
                        <InputLoad>
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="35"
                            visible={true}
                          />
                        </InputLoad>
                      ) : filesEdit ? (
                        filesEdit.map((e) => (
                          <ListArquivosPre>
                            <ButtonText
                              type="button"
                              onClick={() => downloadFile(e)}
                            >
                              {e}
                            </ButtonText>
                            <ButtonX
                              type="text"
                              onClick={() => {
                                deleteFile(e);
                              }}
                            >
                              <IoClose stroke={COLORS.white} size={26} />
                            </ButtonX>
                          </ListArquivosPre>
                        ))
                      ) : null}

                      {files.map((e) => (
                        <ListArquivos>
                          <ListText>{e.name}</ListText>
                          <ButtonX
                            onClick={() => {
                              setFiles((prevState) =>
                                prevState.filter((f) => f.name !== e.name)
                              );

                              setFileName((prevState) =>
                                prevState.filter((f) => f !== e.name)
                              );
                            }}
                          >
                            <IoClose stroke={COLORS.white} size={26} />
                          </ButtonX>
                        </ListArquivos>
                      ))}
                    </InputContainer>
                    <InputContainer>
                      <Label>Links</Label>
                      <InputLinks>
                        <Input value={values.link} name="link" type="text" />
                        {errors.link && touched.link ? (
                          <Errors>{errors.link}</Errors>
                        ) : null}
                        <Button
                          type="button"
                          style={{
                            background: `black`,
                            padding: 0,
                            width: "10%",
                            fontSize: "30px",
                          }}
                          onClick={(e) => addLink(values.link)}
                        >
                          +
                        </Button>
                      </InputLinks>

                      {loadLink ? (
                        <InputLoad>
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="35"
                            visible={true}
                          />
                        </InputLoad>
                      ) : linksEdite ? (
                        linksEdite.map((e) => (
                          <ListArquivosPre>
                            <ListText>{e}</ListText>
                            <ButtonX
                              onClick={() => {
                                deleteLink(e);
                              }}
                            >
                              <IoClose stroke={COLORS.white} size={26} />
                            </ButtonX>
                          </ListArquivosPre>
                        ))
                      ) : null}

                      {links.map((e) => (
                        <ListArquivos>
                          <ListText>
                            {e.length > 20 ? e?.substring(0, 40) + "..." : e}
                          </ListText>
                          <ButtonX
                            onClick={() => {
                              // console.log(e);
                              setLinks((prevState) =>
                                prevState.filter((f) => f !== e)
                              );
                            }}
                          >
                            <IoClose stroke={COLORS.white} size={26} />
                          </ButtonX>
                        </ListArquivos>
                      ))}
                    </InputContainer>
                  </InputGroup>

                  <Info>
                    Para prosseguir com o cadastro é necessário preencher o
                    formulário. Os campos com asterisco (*) são obrigatórios.
                  </Info>

                  <ButtonGroup>
                    <Button
                      onClick={Cancelar}
                      style={{
                        paddingInline: "40px",
                        background: COLORS.softWhite3,
                        color: COLORS.grey,
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button type="submit" style={{ paddingInline: "40px" }}>
                      Editar <BsArrowRight fill={COLORS.white} size={20} />
                    </Button>
                  </ButtonGroup>
                </Form>
              );
            }}
          </Formik>
        </WizardBody>
      )}
    </Card>
  );
}

const Option = styled.option`
  padding: 15px;
  font-size: 18px;
  margin: 6px;
`;

const Errors = styled.span`
  display: block;
  font-size: 12px;
  margin: 6px;
  color: red;
`;

const Info = styled.span`
  display: block;
  font-size: 13px;
  margin-top: 20px;
  color: ${COLORS.grey};
  font-weight: 600;
`;

const Required = styled.span`
  color: red;
  font-weight: 600;
`;

const Card = styled.div`
  max-width: 1000px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
  border-radius: 4px;
`;

const CardTitle = styled.h4`
  margin-top: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${COLORS.blue};
`;

const InputGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 0;
  flex-grow: 1;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 100%;
  }
`;

const ListText = styled.p`
  padding: 0 20px;
  font-size: 15px;
`;

const ButtonText = styled.p`
  padding: 0 20px;
  font-size: 15px;
  cursor: pointer;
`;

const ListArquivos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  background-color: #f4f5f5;
`;

const ListArquivosPre = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  background-color: ${COLORS.green};
`;

const ListUsers = styled.div`
  padding: 15px;
  height: auto;
  flex-wrap: wrap;
  border: 1px solid ${COLORS.softWhite2};
  display: flex;
  justify-content: flex-start;
  gap: 8px;
`;

const InputLinks = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InputLoad = styled.div`
  display: flex;
  justify-content: flex-center;
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled(Field)`
  padding: 15px;
  border-radius: 4px;
  outline: none;
  width: 100%;
  border: 1px solid ${COLORS.softWhite2};
  max-length: 3;
  color: ${COLORS.dark};
`;

const Span = styled.span``;

const ButtonGroup = styled.div`
  gap: 12px;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

export default Wizard;
