import React, { useState } from 'react'
import styled from 'styled-components';
import COLORS from '../../../constants/colors';
import SCREEN_SIZES from '../../../constants/screenSizes';

function WizardBody(props) {
    const [tabActive, setTabActive] = useState(props.tabActive);

    return (
        <Container>{props.children}</Container>
    )
}

const Container = styled.div`
    background: ${COLORS.white};
    padding: 16px 30px;
    border-radius: 0 0  4px 4px;
    @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
        padding: 16px 20px;
    }
`;

export default WizardBody