import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../services/api';
import * as Yup from 'yup';
import { Field, Formik, Form } from 'formik';
import COLORS from '../../constants/colors';
import SCREEN_SIZES from '../../constants/screenSizes';
import Button from '../atoms/Button';

const RegisterForm = () => {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState('');
  // const [nome, setNome] = useState('');
  // const [senha, setSenha] = useState('');
  // const [telefone, setTelefone] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [msg, setMsg] = useState('');

  const handleSubmitAPI = (val) => {
    const { ...values } = val;
    const data =
      api.post('/register', {
        ...values,
        profile: 2,
        status: 0,
      })
        .catch(({ response }) => {
          console.log(response)
          // setApiResponse(response.data.erro);
        });
  };

  const PersonalSchema = Yup.object().shape(
    {
      nome: Yup.string()
        .matches('[a-zA-Z]+', 'Nome não pode conter números')
        .min(4, 'Preencha seu nome Completo')
        .required('Campo Obrigatório'),
      usuario: Yup.string()
        .required('Campo Obrigatório'),
      senha: Yup.string()
        .required('Campo Obrigatório'),
      telefone: Yup.number()
        .min(8, 'Preencha seu telefone Completo')
        .required('Campo Obrigatório'),
      crm: Yup.number()
        .required('Campo Obrigatório')
    },
  )

  return (
    <DivContainer>
      <TitleContainer>
        <Title>Primeira vez por aqui?</Title>
        <Subtitle>Registre-se agora.</Subtitle>
        <p>{msg}</p>
      </TitleContainer>
      <Formik
        initialValues={{ nome: '', telefone: '', usuario: '', senha: '', crm: '' }}
        validationSchema={PersonalSchema}
        onSubmit={(values) => {
          alert('submitou')
          handleSubmitAPI(values);
        }}
      >
        {({ values, errors, touched, handleSubmit }) => (
          <Form>
            <InputGroup>
              <InputContainer>
                <Label>
                  Nome Completo<Required>*</Required>
                </Label>
                <Input value={values.nome} name="nome" type="text" />
                {errors.nome && touched.nome ? (
                  <Errors>{errors.nome}</Errors>
                ) : null}
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <Label>
                  Telefone<Required>*</Required>
                </Label>
                <Input
                  name="telefone"
                  type="text"
                  value={values.telefone}
                />
                {errors.telefone && touched.telefone ? (
                  <Errors>{errors.telefone}</Errors>
                ) : null}
                <Errors>{apiResponse}</Errors>
              </InputContainer>
              <InputContainer>
                <Label>
                  CRM<Required>*</Required>
                </Label>
                <Input name="crm" type="text" value={values.crm} />
                {errors.crm && touched.crm ? (
                  <Errors>{errors.crm}</Errors>
                ) : null}
              </InputContainer>
            </InputGroup>
            <InputGroup>
              <InputContainer>
                <Label>
                  E-mail<Required>*</Required>
                </Label>
                <Input
                  name="usuario"
                  value={values.usuario}
                  type="email"
                />
                {errors.usuario && touched.usuario ? (
                  <Errors>{errors.usuario}</Errors>
                ) : null}
                <Errors>{apiResponse}</Errors>
              </InputContainer>
              <InputContainer>
                <Label>
                  Senha<Required>*</Required>
                </Label>
                <Input autoComplete="off" name="senha" type="password" value={values.senha} />
                {errors.senha && touched.senha ? (
                  <Errors>{errors.senha}</Errors>
                ) : null}
              </InputContainer>
            </InputGroup>
            <ButtonGroup>
              <Button
                onClick={() => navigate('/login', { replace: true })}
                style={{
                  paddingInline: '40px',
                  background: COLORS.softWhite3,
                  color: COLORS.grey,
                }}
              >
                Já possui uma conta?
              </Button>
              <Button type="submit" style={{ paddingInline: '40px' }}>
                Registrar
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </DivContainer>
  );
};

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;
`;


const TitleContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-bottom: 0px;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 0;
  font-weight: 600;
  color: #4b4b4b;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.p`
  margin: 0;
  padding: 0;
  color: #7c94a4;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    font-size: 0.9rem;
  }
`;

const Errors = styled.span`
  display: block;
  font-size: 12px;
  margin: 6px;
  color: red;
`;

const Required = styled.span`
  color: red;
  font-weight: 600;
`;

const InputGroup = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 0;
  flex-grow: 1;
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled(Field)`
  padding: 15px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${COLORS.softWhite2};
  color: ${COLORS.dark};
`;

const ButtonGroup = styled.div`
  gap: 12px;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

export default RegisterForm;
