import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../atoms/Button';
import api from '../../services/api';
import COLORS from '../../constants/colors';
import Moment from 'moment';
import LoadingScreen from '../../pages/LoadingScreen'
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import ReactPaginate from 'react-paginate';
import SCREEN_SIZES from '../../constants/screenSizes';
import { RiListSettingsLine } from 'react-icons/ri';

function TableSearchHistory(props) {
  Moment.locale('pt-BR');
  const cookies = new Cookies();
  const navigate = useNavigate();

  const useQueryString = () => {
    return new URLSearchParams( useLocation().search )
  }
  const query = useQueryString();

  const [historyList, setHistoryList] = useState();
  const [loaded, setLoaded] = useState(false);

  const [espectros, setEspectros] = useState([]);
  const [concentracoes, setConcentracoes] = useState([]);
  const [administracoes, setAdministracoes] = useState([]);

  const [searchEspec, setSearchEspec] = useState(query.get('espectro') !== null ? query.get('espectro') : '');
  const [searchConc, setSearchConc] = useState(query.get('concentracao') !== null ? query.get('concentracao') : '');
  const [searchAdmin, setSearchAdmin] = useState(query.get('administracao') !== null ? query.get('administracao') : '');
  const [searchGenre, setSearchGenre] = useState(query.get('genero') !== null ? query.get('genero') : '');

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 5;

  const [age, setAge] = useState(false);
  const [idadeMin, setIdadeMin] = useState(query.get('idadeMin') !== null ? query.get('idadeMin') : 0);
  const [idadeMax, setIdadeMax] = useState(query.get('idadeMin') !== null ? query.get('idadeMin') : 200);

  


  const handleSearchList = (list) => {

    setHistoryList(list)
    setCurrentItems(list.slice(0, itemsPerPage))
    setPageCount(Math.ceil(list.length / itemsPerPage))
  }
  const handlePagination = (offset, list) => {
    const endOffset = offset + itemsPerPage
    setCurrentItems(list.slice(offset, endOffset))
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage);
    handlePagination(newOffset, historyList);
  };

  const userId = cookies.get('id');

  const getSearchList = () => {
    api.get(`/buscas/historico/${userId}`, {
      params: {
        espectro: searchEspec,
        concentracao: searchConc,
        administracao: searchAdmin,
        genero: searchGenre,
        idadeMin,
        idadeMax,
      }
    }).then((results) => {
      if (results) {
        handleSearchList(results.data);
      }
      setLoaded(true);
    });
  }

  const handleFilters = (list) => {
    getSearchList();
  }

  useEffect(() => {
    getSearchList()
    api.get('/espectros').then((results) => {
      if (results) {
        setEspectros(results.data);
      }
    }).then(() => {
      api.get('/concentracoes').then((results) => {
        if (results) {
          setConcentracoes(results.data);
        }
      });
    }).then(() => {
      api.get('/administracoes').then((results) => {
        if (results) {
          setAdministracoes(results.data);
        }
      });
    });
  }, []);

  const handleAge = (value) => {
    switch (value) {
      case '':
        setIdadeMin(0)
        setIdadeMax(200)
        break;
      
      case '1':
        setIdadeMin(1)
        setIdadeMax(17)
        break;
    
      case '2':
        setIdadeMin(18)
        setIdadeMax(25)
        break;
      
      case '3':
        setIdadeMin(26)
        setIdadeMax(35)
        break;
      
      case '4':
        setIdadeMin(36)
        setIdadeMax(45)
        break;
      
      case '5':
        setIdadeMin(46)
        setIdadeMax(200)
        break;
    }
  }

  useEffect(() => {
    handleAge();
  }, [age])

  useEffect(() => {
    let queryString = [];

    if(idadeMin)
      queryString.push(`idadeMin=${idadeMin}`)
    
    if(idadeMax)
      queryString.push(`idadeMax=${idadeMax}`)
    
    if(searchEspec)
      queryString.push(`espectro=${searchEspec}`)
      
    if(searchAdmin)
      queryString.push(`administracao=${searchAdmin}`)
    
    if(searchConc)
      queryString.push(`concentracao=${searchConc}`)
    
    if(searchGenre)
      queryString.push(`genero=${searchGenre}`)
   navigate(`?${queryString.join('&')}`, {replace:true})
  }, [idadeMin, idadeMax, searchEspec, searchAdmin, searchConc, searchGenre])
  
  
  return (
    <>
      {!loaded &&
        <LoadingScreen loading={!loaded} />
      }
      {loaded &&
        <>

          <CardHeader>
            <CardTitle>{props.tableName}</CardTitle>
            <Button onClick={() => navigate('/suggestedMedicines', { replace: true })}>
              Nova Consulta
            </Button>
          </CardHeader>
          <Filters>
            <InputGroup>
              <InputContainer>
                <Label>
                  Espectro
                </Label>
                <Input
                  onChange={(e) => {
                  if (e.target.value !== 'true') {
                      setSearchEspec(e.target.value)
                    }
                  }}
                  as="select"
                  autoComplete="off"
                  name="espectro"
                >
                  <Option value>
                    Selecione o espectro
                  </Option>
                  {espectros &&
                    espectros.map((el) => {
                      return (
                        <Option key={el.id} value={el.espectro}>{el.espectro}</Option>
                      )
                    })
                  }
                </Input>
            </InputContainer>
            
              <InputContainer>
                <Label>
                  Concentração
                </Label>
                <Input
                  onChange={(e) => {
                    if (e.target.value !== 'true') {
                      setSearchConc(e.target.value)
                    }
                  }}
                  as="select"
                  autoComplete="off"
                  name="espectro"
                >
                  <Option value="">
                    Selecione a concentração
                  </Option>
                  {concentracoes &&
                    concentracoes.map((el) => {
                      return (
                        <Option key={el.id} value={el.concentracao}>{el.concentracao}</Option>
                      )
                    })
                  }
                </Input>
            </InputContainer>

            <InputContainer>
                <Label>
                  Administração
                </Label>
                <Input
                  onChange={(e) => {
                    if (e.target.value !== 'true') {
                      setSearchAdmin(e.target.value)
                    }
                  }}
                  as="select"
                  autoComplete="off"
                  name="espectro"
                >
                  <Option value="">
                    Selecione a Administração
                  </Option>
                  {administracoes &&
                    administracoes.map((el) => {
                      return (
                        <Option key={el.id} value={el.administracao}>{el.administracao}</Option>
                      )
                    })
                  }
                </Input>
            </InputContainer>
            </InputGroup>
        </Filters>
        <Filters>
          <InputGroup>
            <InputContainer>
              <Label>
                Gênero
              </Label>
              <Input
                onChange={(e) => {
                  if (e.target.value !== 'true') {
                    setSearchGenre(e.target.value);
                  }
                }}
                as="select"
                autoComplete="off"
                name="espectro"
              >
                <Option value="">
                  Selecione o gênero
                </Option>
                <Option value="Feminino">Feminino</Option>
                <Option value="Masculino">Masculino</Option>
              </Input>
            </InputContainer>

            <InputContainer>
              <Label>
                Idade
              </Label>
              <Input
                onChange={(e) => {
                  if (e.target.value !== 'true') {
                    handleAge(e.target.value);
                    setAge(!age)
                  }
                }}
                as="select"
                autoComplete="off"
                name="espectro"
              >
                <Option value="">
                  Selecione a faixa etária
                </Option>
                <Option value="1">1 - 17</Option>
                <Option value="2">18 - 25</Option>
                <Option value="3">26 - 35</Option>
                <Option value="4">36 - 45</Option>
                <Option value="5">46+</Option>
              </Input>
            </InputContainer>

            <InputContainer>
              <Button onClick={(e) => {
                e.preventDefault();
                handleFilters()
              }}>
                Buscar
              </Button>
            </InputContainer>
          </InputGroup>
        </Filters>
          <Card>
            <CardBody>
              <TableReact>
                <TableHead>
                  <TableRow role="row">
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Data da Busca
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Espectro
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Concentração
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Administração
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Doença
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Idade
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Gênero
                    </TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loaded && currentItems.length > 0 &&
                    currentItems.map((busca) => {
                      return (
                        <TableRow key={busca.data_busca}>
                          <TableCell>{Moment(busca.data_busca).format('DD/MM/YY  -  HH:MM')}</TableCell>
                          <TableCell>{espectros.map((item) => {
                            if (item.id === busca.espectro)
                              return item.espectro
                          })}</TableCell>
                          <TableCell>{concentracoes.map((item) => {
                            if (item.id === busca.concentracao)
                              return item.concentracao
                          })}</TableCell>
                          <TableCell>{administracoes.map((item) => {
                            if (item.id === busca.administracao)
                              return item.administracao
                          })}</TableCell>
                          <TableCell>{busca.doenca}</TableCell>
                          <TableCell>{busca.idade}</TableCell>
                          <TableCell>{busca.genero}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </TableReact>
            </CardBody>
          </Card>
          <Pagination
            breakLabel="..."
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={<HiChevronLeft size={20} fill={COLORS.dark} />}
            nextLabel={<HiChevronRight size={20} fill={COLORS.dark} />}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        </>
      }
    </>
  );
}

const Filters = styled.form`
  width: 100%;
  background: ${COLORS.white};
  border-radius: 0.2857rem;
  padding: 15px;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const Option = styled.option`
  padding: 15px;
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 0 20px 0;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
      width: 100%;
  }
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${COLORS.softWhite2};
  color: ${COLORS.dark};
`;


const Pagination = styled(ReactPaginate).attrs({
  activeClassName: 'active', // default to "disabled"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  list-style-type: none;
  padding: 0;
  li a {
    border-radius: 4px;
    padding-inline: .7rem;
    height: 32px;
    background: ${COLORS.white};
    border: 1px solid ${COLORS.softWhite2};
    cursor: pointer;
    display: flex;
    align-items:center;
  }
  li.previous a,
  li.next a,
  li.break a {
  }
  li.active a {
    background-color: ${COLORS.green};
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.2857rem;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.softWhite};
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    overflow: auto;
  }
}
`;
const CardHeader = styled.div`
  margin: 15px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardTitle = styled.h4`
  color: ${COLORS.blue};
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: .9rem;
  }
`;

const CardBody = styled.div`
  padding: 15px;
`;

const TableReact = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  color: ${COLORS.dark};
`;

const TableHead = styled.thead`
  width: 100%;
`;

const TableRow = styled.tr`
  width: 100%;
  &:nth-child(even) {
    background-color: ${COLORS.softWhite4};
  }
`;

const TableHeader = styled.th`
  padding: 5px;
  line-height: 2.5em;
  text-align: left;
  font-weight: 500;
  color: ${COLORS.grey};
  font-size: 15px;
  text-overflow: ellipsis;
   @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    white-space: nowrap;
    padding-right: 25px;
  }
`;

const TableBody = styled.tbody`
  overflow: auto;
`;

const TableCell = styled.td`
  padding: 12px 7px;
  vertical-allign: middle;
  margin: auto;
  text-overflow: ellipsis;
  border-top: 1px solid ${COLORS.softWhite3};
  color: ${COLORS.dark};
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: .8rem;
    padding-right: 25px;
  }
`;

export default TableSearchHistory;
