import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import Button from "../atoms/Button";
import api from "../../services/api";
import COLORS from "../../constants/colors";
import { HasAccess, usePermify } from "@permify/react-role";
import ModalDelete from "./Modal/ModalDelete";
import ModalInfo from "./Modal/ModalInfo";
import LoadingScreen from "../../pages/LoadingScreen";
import { useNavigate, useLocation } from "react-router-dom";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import {
  RiDeleteBinLine,
  RiEdit2Fill,
  RiInformationLine,
} from "react-icons/ri";
import { TiWarning } from "react-icons/ti";
import SCREEN_SIZES from "../../constants/screenSizes";
import moment from "moment/moment";
import { BsPerson } from "react-icons/bs";

function TableCursos(props) {
  const navigate = useNavigate();
  const [cursosList, setcursosList] = useState();
  const [uploadsList, setUploadsList] = useState();
  const [loaded, setLoaded] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [cursoDeletado, setCursoDeletado] = useState({});

  const [modalInfo, setModalInfo] = useState(false);
  const [cursoInfo, setCursoInfo] = useState({});
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 5;
  const [searchName, setSearchName] = useState("");

  const handlecursosList = (list) => {
    setcursosList(list);
    setCurrentItems(list.slice(0, itemsPerPage));
    setPageCount(Math.ceil(list.length / itemsPerPage));
  };

  const handlePagination = (offset, list) => {
    const endOffset = offset + itemsPerPage;
    setCurrentItems(list.slice(offset, endOffset));
  };

  const userId = JSON.parse(localStorage.getItem("__permifyUser")).id;

  const getcursos = () => {
    if (searchName) {
      // api.get(`/cursos/search?nome=${searchName}`).then((results) => {
      //   if (results) {
      //     handlecursosList(results.data);
      //   }
      //
      // });
      const lowerSearch = searchName.toLowerCase();
      const result = cursosList.filter((c) => {
        return c.nome.toLowerCase().includes(lowerSearch);
      });
      handlecursosList(result);
      setLoaded(true);
    } else if (props.type === 1) {
      api.get("/cursos").then((results) => {
        if (results) {
          // console.log(results);
          handlecursosList(results.data);
        }
        setLoaded(true);
      });
    } else if (props.type === 2) {
      api.get(`/cursos/acesso/${userId}`).then((results) => {
        if (results) {
          // console.log(results);
          handlecursosList(results.data);
        }
        setLoaded(true);
      });
    }
  };

  const clearFilters = async () => {
    setLoaded(false);
    await setSearchName("");
    getcursos();
  };

  const handleFilters = () => {
    setLoaded(false);
    getcursos();
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    handlePagination(newOffset, cursosList);
  };

  const handleDelete = (courseId, course) => {
    setCursoDeletado({ courseId: courseId, nome: course });
    setModalConfirm(true);
  };

  const handleInfo = async (courseId) => {
    await api
      .get(`/cursos/uploadFiles/${courseId}`, {})
      .then((response) => {
        setUploadsList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setCursoInfo({ courseId: courseId, uploadsList: uploadsList });
    setModalInfo(true);
  };

  const formatData = (date) => {
    return moment(date).format("DD/MM/yyyy");
  };

  useEffect(() => {
    getcursos();
  }, [loaded]);

  return (
    <>
      {!loaded && <LoadingScreen loading={!loaded} />}
      {loaded && (
        <>
          {modalConfirm && (
            <ModalDelete
              type={"cursos"}
              setModalConfirm={setModalConfirm}
              active={modalConfirm}
              title={`Excluir curso?`}
              subtitle={`Você tem certeza que quer excluir o curso "${cursoDeletado.nome}"?`}
              courseId={cursoDeletado.courseId}
            >
              <Warning>
                <div>
                  <TiWarning fill={COLORS.blue} size={28} />
                </div>
                <div>
                  <WarningTitle>Atenção</WarningTitle>
                  <WarningText>
                    A exclusão desse curso é definitiva.
                  </WarningText>
                </div>
              </Warning>
            </ModalDelete>
          )}
          {modalInfo && (
            <ModalInfo
              type={"cursos"}
              setModalInfo={setModalInfo}
              active={modalInfo}
              title={`Informações do curso`}
              courseId={cursoInfo.courseId}
            ></ModalInfo>
          )}
          <CardHeader>
            <CardTitle>{props.tableName}</CardTitle>
            {props.concentracao && (
              <Button onClick={() => window.location.reload(false)}>
                Nova Consulta
              </Button>
            )}
            <HasAccess roles={["Administrador"]}>
              {!props.concentracao && (
                <Button
                  onClick={() =>
                    navigate("/coursesRegister", { replace: true })
                  }
                >
                  Cadastrar Curso
                </Button>
              )}
            </HasAccess>
          </CardHeader>
          {props.filter && (
            <>
              <Filters>
                <InputGroup>
                  <InputContainer>
                    <Label>Nome do Curso:</Label>
                    <TextInput
                      value={searchName}
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleFilters();
                      }}
                    >
                      Buscar
                    </Button>
                  </InputContainer>
                  <InputContainer>
                    <Button
                      style={{ backgroundColor: "grey" }}
                      onClick={(e) => {
                        e.preventDefault();
                        clearFilters();
                      }}
                    >
                      Limpar Busca
                    </Button>
                  </InputContainer>
                </InputGroup>
              </Filters>
            </>
          )}

          <Card>
            <CardBody>
              <TableReact>
                <TableHead>
                  <TableRow role="row">
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Nome do Curso
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Descrição
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Data de Início
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Data do Término
                    </TableHeader>

                    <TableHeader
                      colspan="2"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Ações
                    </TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loaded &&
                    currentItems.length > 0 &&
                    currentItems.map((curso) => {
                      return (
                        <TableRow key={curso.id}>
                          <TableCell>{curso.nome}</TableCell>
                          <TableCell>
                            {curso.descricao.length > 30
                              ? curso.descricao?.substring(0, 50) + "..."
                              : curso.descricao}
                          </TableCell>
                          <TableCell>{formatData(curso.dataInicio)}</TableCell>
                          <TableCell>{formatData(curso.dataFim)}</TableCell>
                          <HasAccess roles={["Administrador"]}>
                            <TableCell className="flex">
                              <RiDeleteBinLine
                                onClick={() =>
                                  handleDelete(curso.id, curso.nome)
                                }
                                fill={COLORS.dark}
                                style={{ cursor: "pointer" }}
                                size={"20px"}
                              />
                              <RiEdit2Fill
                                onClick={() => {
                                  navigate(`/courses/edit/${curso.id}`, {
                                    state: {
                                      id: curso.id,
                                    },
                                  });
                                }}
                                fill={COLORS.dark}
                                style={{ cursor: "pointer" }}
                                size={"20px"}
                              />
                              <RiInformationLine
                                onClick={() => {
                                  navigate(`/courses/${curso.id}`, {
                                    state: {
                                      id: curso.id,
                                    },
                                  });
                                }}
                                fill={COLORS.dark}
                                style={{ cursor: "pointer" }}
                                size={"20px"}
                              />

                              <BsPerson
                                onClick={() => {
                                  navigate(`/courses/viewusers/${curso.id}`, {
                                    state: {
                                      id: curso.id,
                                    },
                                  });
                                }}
                                fill={COLORS.dark}
                                style={{ cursor: "pointer" }}
                                size={"20px"}
                              />
                            </TableCell>
                          </HasAccess>
                          <HasAccess roles={["Medico"]}>
                            <TableCell className="flex">
                              <RiInformationLine
                                onClick={() => {
                                  navigate(`/courses/${curso.id}`, {
                                    state: {
                                      id: curso.id,
                                    },
                                  });
                                }}
                                fill={COLORS.dark}
                                style={{ cursor: "pointer" }}
                                size={"20px"}
                              />
                            </TableCell>
                          </HasAccess>
                        </TableRow>
                      );
                    })}
                  {loaded && currentItems.length === 0 && (
                    <TableRow>
                      <TableCell>
                        Nenhum Curso encontrado com os parâmetros informados.
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <HasAccess roles={["Administrador"]}>
                        <TableCell></TableCell>
                      </HasAccess>
                    </TableRow>
                  )}
                </TableBody>
              </TableReact>
            </CardBody>
          </Card>
          <Pagination
            breakLabel="..."
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={<HiChevronLeft size={20} fill={COLORS.dark} />}
            nextLabel={<HiChevronRight size={20} fill={COLORS.dark} />}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        </>
      )}
    </>
  );
}

const Filters = styled.form`
  width: 100%;
  background: ${COLORS.white};
  border-radius: 0.2857rem;
  padding: 15px;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const TextInput = styled.input`
  width: 100%;
  padding: 6px 10px;
  border-radius: 6px;
  margin-top: 10px;
  margin: 0;
  outline: 0;
  border: 1px solid #d5d5d5;
`;

const Option = styled.option`
  padding: 15px;
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 0 20px 0;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 100%;
  }
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${COLORS.softWhite2};
  color: ${COLORS.dark};
`;

const WarningTitle = styled.h6`
  margin: 0;
  font-size: 1rem;
  color: ${COLORS.blue};
`;

const WarningText = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-top: 8px;
  color: ${COLORS.blue};
`;

const Warning = styled.div`
  height: 100px;
  width: 100%;
  background: #4c6c8c0f;
  display: block;
  border-radius: 4px;
  border-left: 5px solid ${COLORS.blue};
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const Pagination = styled(ReactPaginate).attrs({
  activeClassName: "active", // default to "disabled"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  list-style-type: none;
  padding: 0;
  li a {
    border-radius: 4px;
    padding-inline: 0.7rem;
    height: 32px;
    background: ${COLORS.white};
    border: 1px solid ${COLORS.softWhite2};
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  li.previous a,
  li.next a,
  li.break a {
  }
  li.active a {
    background-color: ${COLORS.green};
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.2857rem;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.softWhite};
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    overflow: auto;
  }
}
`;
const CardHeader = styled.div`
  margin: 15px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardTitle = styled.h4`
  color: ${COLORS.blue};
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 0.9rem;
    margin-bottom: 0;
  }
`;

const CardBody = styled.div`
  padding: 15px;
`;

const TableReact = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  color: ${COLORS.dark};
`;

const TableHead = styled.thead`
  width: 100%;
`;

const TableRow = styled.tr`
  width: 100%;
  &:nth-child(even) {
    background-color: ${COLORS.softWhite4};
  }
`;

const TableHeader = styled.th`
  padding: 5px;
  line-height: 2.5em;
  text-align: left;
  font-weight: 500;
  color: ${COLORS.grey};
  font-size: 15px;
  text-overflow: ellipsis;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding-right: 25px;
  }
`;

const TableBody = styled.tbody`
  overflow: auto;
`;

const TableCell = styled.td`
  padding: 12px 7px;
  vertical-allign: middle;
  margin: auto;
  text-overflow: ellipsis;
  border-top: 1px solid ${COLORS.softWhite3};
  color: ${COLORS.dark};
  &.flex {
    display: flex;
    gap: 12px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 0.8rem;
    padding: 12px 15px;
  }
`;

export default TableCursos;
