import React from "react";
import { HasAccess } from "@permify/react-role";
import styled from "styled-components";
import COLORS from "../../constants/colors";
import CourseRegisterWizard from "../../components/organisms/CourseRegisterWizard";
import Root from "../../components/organisms/Root";

function CourseRegister() {
  const course = {
    nome: "",
    descricao: "",
    dataInicio: "",
    dataFim: "",
  };

  return (
    <Root active={"courses"}>
      <HasAccess
        roles={["Administrador"]}
        renderAuthFailed={
          <p>Erro: Você não tem permissão para acessar essa tela.</p>
        }
      >
        <CourseRegisterWizard course={course} />
      </HasAccess>
    </Root>
  );
}

const Card = styled.div`
  max-width: 800px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
  border-radius: 4px;
  background: ${COLORS.white};
  padding: 16px 30px;
`;

const CardTitle = styled.h4`
  margin-top: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${COLORS.blue};
`;

export default CourseRegister;
