import React from "react";
import styled from "styled-components";

const LoginOpt = (props) => {
  //const url = "#";
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {/* <TextH4>
        <input type="checkbox" checked={checked} onChange={handleChange} id="checkbox1" />
        <label htmlFor="checkbox1">Lembrar minha senha</label>
      </TextH4> */}
      <PassForgot href="recovery">Esqueci minha senha</PassForgot>
    </div>
  );
};

const PassForgot = styled.a`
  display: flex;
  align-items: flex-end;
  font-size: 13px;
  font-family: "Public Sans", sans-serif;
  color: #7C94A4;
  font-weight: 500;
  margin-bottom: 12px;
`;

export default LoginOpt;
