import React from 'react'
import UserRegisterWizard from '../components/organisms/UserRegisterWizard'
import Root from '../components/organisms/Root'
import { HasAccess } from '@permify/react-role'

function UserRegister() {
    return (
        <Root active={'users'}>
            <HasAccess
                roles={["Administrador"]}
                renderAuthFailed={<p>Erro: Você não tem permissão para acessar essa tela.</p>}
            >
                <UserRegisterWizard />
            </HasAccess>
        </Root>
    )
}

export default UserRegister