import React from "react";
import TableCursos from "../../components/molecules/TableCursos";
import Root from "../../components/organisms/Root";
import { HasAccess } from "@permify/react-role";

function Course() {
  return (
    <Root active={"courses"}>
      {/* <HasAccess
        roles={["Administrador"]}
        renderAuthFailed={
          <p>Erro: Você não tem permissão para acessar essa tela.</p>
        }
      > */}
      <HasAccess roles={["Administrador"]}>
        <TableCursos filter={true} tableName={"Cursos Cadastrados"} type={1} />
      </HasAccess>
      <HasAccess roles={["Medico"]}>
        <TableCursos filter={true} tableName={"Cursos Cadastrados"} type={2} />
      </HasAccess>
    </Root>
  );
}

export default Course;
