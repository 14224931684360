import React, { useState } from 'react'
import COLORS from '../../../constants/colors.js';
import styled from 'styled-components';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsCircle } from "react-icons/bs";
import SCREEN_SIZES from '../../../constants/screenSizes.js';

function useWizardHeader(header, clickDisabled) {
    const [tabActive, setTabActive] = useState(header[0]);
    const [tabFilled, setTabFilled] = useState([]);
    return {
        setTabActive,
        tabActive,
        setTabFilled,
        tabFilled,
        render: (
            <Container>
                {header.map((title) => {
                    return (
                        <Title key={title} active={tabActive === title}>
                            {tabFilled.includes(title) &&
                                <AiOutlineCheckCircle fill={COLORS.green} />
                            }
                            {!tabFilled.includes(title) &&
                                <BsCircle fill={COLORS.grey} />
                            }

                            {title}
                        </Title>
                    )
                }
                )}
            </Container>
        )
    }
}

const Container = styled.div`
    display: flex;
    background: ${COLORS.white};
    border-bottom: 1px solid ${COLORS.softWhite2};
    border-radius: 4px;
    @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
        display: none;
    }
`;

const Title = styled.a`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: ${props => props.active ? COLORS.green : COLORS.grey};
    font-weight: 400;
    border-bottom: 2px solid ${props => props.active ? COLORS.green : "transparent"};
    padding: 20px 30px;
    padding-bottom: 20px;
    border-right: 1px solid ${COLORS.softWhite2};
    pointer-events: none;
`;

export default useWizardHeader