import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import styled from 'styled-components';
import COLORS from '../constants/colors';

function LoadingScreen({ loading }) {
    return (
        <Container>
            {loading && (
                <ThreeDots height="35" color={COLORS.greenSoft} ariaLabel="loading" wrapperStyle />
            )}
        </Container>
    )
}

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #00000099;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default LoadingScreen