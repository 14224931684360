import React from "react";
import styled from "styled-components";
import Logo from "../atoms/Logo";
import { BsLightningCharge } from "react-icons/bs";
import { MdOutlineHelpOutline } from "react-icons/md";
import { AiOutlineFileSearch } from "react-icons/ai";
import { RiMedicineBottleLine, RiMentalHealthLine } from "react-icons/ri";
import COLORS from "../../constants/colors";
import { HasAccess } from "@permify/react-role";
import SCREEN_SIZES from "../../constants/screenSizes";
import { FiUsers, FiBook } from "react-icons/fi";

function SideBar({ active, className }) {
  return (
    <Container className={className}>
      <Logo />
      <Links>
        <HasAccess roles={["Administrador", "Medico"]}>
        <Link className={active === "suggestedMedicines" ? "active" : ""}>
          <LinkSingle
            href="/suggestedMedicines"
            className={active === "suggestedMedicines" ? "active" : ""}
          >
            <BsLightningCharge
              style={{
                fill: active === "suggestedMedicines" ? "#2e7e61" : "#878787",
              }}
            />
            Medicações Sugeridas
          </LinkSingle>
        </Link>
        </HasAccess>
        
        <HasAccess roles={["Administrador", "Medico"]}>
          <Link className={active === "search" ? "active" : ""}>
            <LinkSingle
              href="/mySearch"
              className={active === "search" ? "active" : ""}
            >
              <AiOutlineFileSearch
                style={{ fill: active === "search" ? "#2e7e61" : "#878787" }}
              />
              Minhas buscas
            </LinkSingle>
          </Link>
        </HasAccess>

        <HasAccess roles={["Administrador", "Empresa"]}>
          <Link className={active === "medicines" ? "active" : ""}>
            <LinkSingle
              href="/medicines"
              className={active === "medicines" ? "active" : ""}
            >
              <RiMedicineBottleLine
                style={{ fill: active === "medicines" ? "#2e7e61" : "#878787" }}
              />
              Medicamentos
            </LinkSingle>
          </Link>
        </HasAccess>

        <HasAccess roles={["Administrador"]}>
          <Link className={active === "medicos" ? "active" : ""}>
            <LinkSingle
              href="/medicos"
              className={active === "medicos" ? "active" : ""}
            >
              <RiMentalHealthLine
                style={{ fill: active === "medicos" ? "#2e7e61" : "#878787" }}
              />
              Médicos
            </LinkSingle>
          </Link>
        </HasAccess>

        <HasAccess roles={["Administrador"]}>
          <Link className={active === "users" ? "active" : ""}>
            <LinkSingle
              href="/users"
              className={active === "users" ? "active" : ""}
            >
              <FiUsers
                style={{ stroke: active === "users" ? "#2e7e61" : "#878787" }}
              />
              Usuários
            </LinkSingle>
          </Link>
        </HasAccess>

        <HasAccess roles={["Administrador", "Medico"]}>
          <Link className={active === "courses" ? "active" : ""}>
            <LinkSingle
              href="/courses"
              className={active === "courses" ? "active" : ""}
            >
              <FiBook
                style={{ stroke: active === "courses" ? "#2e7e61" : "#878787" }}
              />
              Cursos
            </LinkSingle>
          </Link>
        </HasAccess>

          <Link className={active === "support" ? "active" : ""}>
            <LinkSingle
              href="/support"
              className={active === "support" ? "active" : ""}
            >
              <MdOutlineHelpOutline
                style={{
                  fill: active === "support" ? "#2e7e61" : "#878787",
                }}
              />
              Suporte
            </LinkSingle>
          </Link>
      </Links>
    </Container>
  );
}

const Container = styled.aside`
  background: ${COLORS.white};
  grid-area: sidenav;
  padding: 40px 24px;
  border-right: 1px solid ${COLORS.softWhite2};
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    display: none;
  }
`;

const Links = styled.ul`
  list-style: none;
  margin: 40px 0;
  padding: 0;
`;

const Link = styled.li`
  padding: 15px 20px;
  margin-bottom: 12px;
  border-radius: 8px;
  &.active {
    background: ${COLORS.greenSoft};
  }
`;

const LinkSingle = styled.a`
  color: #878787;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  &.active {
    color: ${COLORS.greenDark};
  }
`;

export default SideBar;
