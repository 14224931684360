export function translateOilsType(type) {
  switch (type) {
    case "oils":
      return "Óleos";
    case "gummies":
      return "Balas";
    default:
      return "Outros";
  }
}

export function translateTopicalType(type) {
  switch (type) {
    case "creams":
      return "Cremes";
    case "ointments":
      return "Pomadas";
    case "roll-ons":
      return "Rolon";
    default:
      return "Outros";
  }
}

export const extracts = [
  { "value": "completo", "label": "Completo" },
  { "value": "ampliado", "label": "Ampliado" },
  { "value": "isolado", "label": "Isolado" }
]

export const cannabinoids = [
  { "value": 1, "label": "CBD" },
  { "value": 2, "label": "THC-D8" },
  { "value": 3, "label": "THC-D9" },
  { "value": 4, "label": "CBG" },
  { "value": 5, "label": "CBN" },
  { "value": 6, "label": "THCV" },
  { "value": 7, "label": "Formas Ácidas" },
  { "value": 8, "label": "Outros" }
]

export const cannabinoidTypes = [
  { "value": "prioritário", "label": "Prioritário" },
  { "value": "igualitário", "label": "Igualitário" }
]

export const administrationRoutes = [
    { "value": 1, "label": "Oral" },
    { "value": 2, "label": "Tópica" },
    { "value": 3, "label": "Transdérmicos" },
    { "value": 4, "label": "Outros" }
]

export const oralTypes = [
  { "value": "oils", "label": "Óleos" },
  { "value": "gummies", "label": "Balas" },
  { "value": "others", "label": "Outros" }
]

export const topicTypes = [
  { "value": "creams", "label": "Cremes" },
  { "value": "ointments", "label": "Pomadas" },
  { "value": "roll-ons", "label": "Rolos" },
  { "value": "others", "label": "Outros" }
]

export const concentrationsOptions = [
  { "value": 1, "label": "10" },
  { "value": 2, "label": "20" },
  { "value": 3, "label": "30" },
  { "value": 4, "label": "40" },
  { "value": 5, "label": "50" },
  { "value": 6, "label": "60" },
  { "value": 7, "label": "70" },
  { "value": 8, "label": "80" },
  { "value": 9, "label": "90" },
  { "value": 10, "label": "100"},
  { "value": 11, "label": "110" },
  { "value": 12, "label": "120" },
  { "value": 13, "label": "130" },
  { "value": 14, "label": "140" },
  { "value": 15, "label": "150" },
  { "value": 16, "label": "160" },
  { "value": 17, "label": "170" },
  { "value": 18, "label": "180" },
  { "value": 19, "label": "190" },
  { "value": 20, "label": "200" }
];

export const prescriptionTypes = [
  { "value": 1, "label": "Branca" },
  { "value": 2, "label": "Simples" },
  { "value": 3, "label": "B (Azul)" },
]

export const mlIdsOptions = [
  { "value": 1, "label": "100ml" },
  { "value": 2, "label": "10ml" },
  { "value": 3, "label": "120ml" },
  { "value": 4, "label": "15ml" },
  { "value": 5, "label": "20 gummies" },
  { "value": 6, "label": "30 comprimidos" },
  { "value": 7, "label": "30 gomas" },
  { "value": 8, "label": "30 unidades" },
  { "value": 9, "label": "30ml" },
  { "value": 10, "label": "4 OZ" },
  { "value": 11, "label": "40 unidades" },
  { "value": 12, "label": "48g" },
  { "value": 13, "label": "5,5 OZ" },
  { "value": 14, "label": "60g" },
  { "value": 15, "label": "60ml" },
  { "value": 16, "label": "70g" },
  { "value": 17, "label": "90ml" },
  { "value": 18, "label": "96g" },
]