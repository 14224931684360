import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../atoms/Button';
import LoginInput from '../atoms/LoginInput';
import { Rings } from 'react-loader-spinner';
import SCREEN_SIZES from '../../constants/screenSizes';
import useWizardHeader from './Wizard/useWizardHeader';
import WizardBody from '../molecules/Wizard/WizardBody';
import api from '../../services/api';

const ResetPassword = () => {
  const header = ['Informações', 'Acesso'];
  const { tabActive, setTabActive, tabFilled, setTabFilled, render } =
    useWizardHeader(header);

  const navigate = useNavigate();
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const Voltar = () => {
    setTabActive(header[0]);
  };
  const Cancelar = () => {
    navigate('/login', { replace: true });
  };

  const sendToken = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setLoading(true);
      await api
        .post('/esqueci_senha', {
          usuario: usuario,
        })
        .then(() => {
          setMsg(null);
          setLoading(false);
          setTabFilled(tabFilled + header[0]);
          setTabActive(header[1]);
        })
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setMsg('Endereço de email não encontrado em nossa base.');
        } else {
          setMsg('Ocorreu um erro. Por favor tente novamente mais tarde.');
        }
      }
      setLoading(false);
    }
  };

  const ChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api
        .post('/resetar_senha', {
          usuario,
          password,
          token
        })
        .then(() => {
          navigate('/login', { replace: true });
        });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setMsg('Usuário ou senha inválidos.');
        } else if (error.response.data.error === 'Token inválido') {
          setMsg('O Token informado é inválido, verifique sua caixa de e-mail.');
        } else {
          setMsg('Ocorreu um erro, tente novamente mais tarde.');
        }
      }
      setLoading(false);
    }
  };

  return (
    <>
      {tabActive === header[0] &&
        <DivContainer
          onSubmit={sendToken}
        >
          <TitleContainer>
            <Title>Esqueceu sua senha?</Title>
            <Subtitle>Digite seu endereço de e-mail</Subtitle>
            <Error>{msg}</Error>
          </TitleContainer>
          <LoginInput
            value={usuario}
            onChange={(e) => setUsuario(e.target.value.trim())}
            type="email"
            labeltext="Email"
          />
          <Button style={{ marginTop: '16px', width: '100%' }}>
            {!loading && 'Prosseguir'}
            {loading && (
              <Rings height="35" color="white" ariaLabel="loading" wrapperStyle />
            )}
          </Button>
          <Back href="login">Voltar para tela de login</Back>
        </DivContainer>
      }
      {
        tabActive === header[1] &&
        <DivContainer
          onSubmit={ChangePassword}
        >
          <TitleContainer>
            <Title>Cadastre sua nova senha.</Title>
            <Subtitle>Insira o token recebido por e-mail e sua nova senha para continuar.</Subtitle>
            <Error>{msg}</Error>
          </TitleContainer>
          <WizardBody>
            <>
              <LoginInput
                value={token}
                onChange={(e) => setToken(e.target.value.trim())}
                type="text"
                labeltext="Token"
              />
              <LoginInput
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
                type="password"
                labeltext="Nova senha"
              />
            </>
            <Button type="submit" style={{ marginTop: '16px', width: '100%' }}>
              {!loading && 'Prosseguir'}
              {loading && (
                <Rings height="35" color="white" ariaLabel="loading" wrapperStyle />
              )}
            </Button>
            <Back onClick={Cancelar}>Voltar para tela de login</Back>
          </WizardBody>
        </DivContainer>
      }
    </>
  );
};

const Error = styled.p`
  color: red;
  font-size: .8rem;
  text-align: center;
`;

const Back = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "Public Sans", sans-serif;
  color: #7C94A4;
  text-align: center;
  margin-top: 36px;
  font-weight: 500;
  cursor: pointer;
`;

const DivContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
`;

const TitleContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    margin-bottom: 0px;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 0;
  font-weight: 600;
  color: #4b4b4b;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.p`
  text-align: center;
  margin: 0;
  padding: 0;
  color: #7c94a4;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    font-size: 0.9rem;
  }
`;

export default ResetPassword;
