import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import Button from "../../atoms/Button";
import api from "../../../services/api";
import COLORS from "../../../constants/colors";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingScreen from "../../../pages/LoadingScreen";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { TiWarning } from "react-icons/ti";
import Modal from "../Modal/ModalDelete";
import SCREEN_SIZES from "../../../constants/screenSizes";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

function ViewAccessCourse(props) {
  const navigate = useNavigate();

  const [usersList, setUsersList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalActivated, setModalActivated] = useState(false);
  const [userDeleted, setUserDeleted] = useState({});
  const [userActivated, setUserActivated] = useState({});

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 10;
  const [name, setName] = useState();

  const handleUserList = (list) => {
    setUsersList(list);
    setCurrentItems(list.slice(0, itemsPerPage));
    setPageCount(Math.ceil(list.length / itemsPerPage));
  };

  const handlePagination = (offset, list) => {
    console.log(usersList);
    const endOffset = offset + itemsPerPage;
    setCurrentItems(list.slice(offset, endOffset));
  };

  const setUsers = async (data) => {
    const accessCurso = await api
      .get(`/acessocurso/${props.cursoId}`)
      .then((res) => {
        if (res) {
          const filterUsers = data
            .map((user) => {
              if (res.data.length > 0) {
                const d = res.data.filter((curso) => {
                  return curso.usuario_id === user.id;
                });
                if (d.length > 0) {
                  return {
                    ...user,
                    status: true,
                    accessId: d[0].id,
                  };
                }
              }
              return {
                ...user,
                status: false,
              };
            })
            .sort(function (x, y) {
              return x === y ? 0 : x.status ? -1 : 1;
            });
          // setUsersList(filterUsers);
          handleUserList(filterUsers);
        }
        setLoaded(true);
      });
  };

  const getUsers = () => {
    if (name) {
      api.get(`/medicos/search?nome=${name}`).then((results) => {
        if (results) {
          const filterUsers = results.data.map((u) => {
            return {
              id: u.id,
              nome: u.nome,
              usuario: u.usuario,
            };
          });
          setUsers(filterUsers);
        }
        setLoaded(true);
      });
    } else {
      api.get("/medicos").then((results) => {
        if (results) {
          const filterUsers = results.data.map((u) => {
            return {
              id: u.id,
              nome: u.nome,
              usuario: u.usuario,
            };
          });
          setUsers(filterUsers);
        }
      });
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleActivate = (userId, user, status, accessId) => {
    setUserActivated({ id: userId, usuario: user, status, accessId });
    setModalActivated(true);
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    handlePagination(newOffset, usersList);
  };

  const handleFilters = () => {
    getUsers();
  };

  return (
    <>
      {modalActivated && (
        <Modal
          setModalConfirm={setModalActivated}
          active={modalActivated}
          title={`${
            userActivated.status === false ? "Liberar" : "Bloquear"
          } acesso do usuário?`}
          subtitle={`Você tem certeza que deseja ${
            userActivated.status === false ? "liberar" : "bloquear"
          } o acesso do usuário "${userActivated.usuario}" ao curso?`}
          courseId={props.cursoId}
          userId={userActivated.id}
          userStatus={!userActivated.status}
          accessId={userActivated.accessId}
          type={
            userActivated.status === false
              ? "activate_access"
              : "inactivate_access"
          }
        >
          <Warning>
            <div>
              <TiWarning fill={COLORS.blue} size={28} />
            </div>
            <div>
              <WarningTitle>Atenção</WarningTitle>
              <WarningText>
                Liberar ou bloquear o usuário impactará no acesso deste ao
                curso.
              </WarningText>
            </div>
          </Warning>
        </Modal>
      )}

      {!loaded && <LoadingScreen loading={!loaded} />}
      {loaded && (
        <>
          <CardHeader>
            <CardTitle>{props.tableName}</CardTitle>
          </CardHeader>
          <Filters>
            <InputGroup>
              <InputContainer>
                <Label>Nome:</Label>

                <TextInput
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </InputContainer>
              <InputContainer>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleFilters();
                  }}
                >
                  Buscar
                </Button>
              </InputContainer>
            </InputGroup>
          </Filters>
          <Card>
            <CardBody>
              <TableReact>
                <TableHead>
                  <TableRow role="row">
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Nome
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      E-mail
                    </TableHeader>
                    {/* <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      CRM
                    </TableHeader> */}
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Acesso
                    </TableHeader>
                    <TableHeader
                      colspan="2"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Ações
                    </TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loaded &&
                    currentItems.length > 0 &&
                    currentItems.map((user) => {
                      // console.log(user);
                      return (
                        <TableRow key={user.id}>
                          <TableCell>{user.nome}</TableCell>
                          <TableCell>{user.usuario}</TableCell>
                          {/* <TableCell>{user.crm}</TableCell> */}
                          <TableCell>
                            {user.status === false ? "Bloqueado" : "Liberado"}
                          </TableCell>
                          <TableCell className="flex">
                            {user.status !== false && (
                              <BsToggleOn
                                onClick={() =>
                                  handleActivate(
                                    user.id,
                                    user.usuario,
                                    user.status,
                                    user.accessId
                                  )
                                }
                                fill={COLORS.dark}
                                style={{ cursor: "pointer" }}
                                size={"20px"}
                              ></BsToggleOn>
                            )}
                            {user.status === false && (
                              <BsToggleOff
                                onClick={() =>
                                  handleActivate(
                                    user.id,
                                    user.usuario,
                                    user.status,
                                    user.accessId
                                  )
                                }
                                fill={COLORS.dark}
                                style={{ cursor: "pointer" }}
                                size={"20px"}
                              ></BsToggleOff>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </TableReact>
            </CardBody>
          </Card>
          <Pagination
            breakLabel="..."
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={<HiChevronLeft size={20} fill={COLORS.dark} />}
            nextLabel={<HiChevronRight size={20} fill={COLORS.dark} />}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        </>
      )}
    </>
  );
}

const Filters = styled.form`
  width: 100%;
  background: ${COLORS.white};
  border-radius: 0.2857rem;
  padding: 15px;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const TextInput = styled.input`
  width: 100%;
  padding: 6px 10px;
  border-radius: 6px;
  margin-top: 10px;
  margin: 0;
  outline: 0;
  border: 1px solid #d5d5d5;
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 0 20px 0;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 100%;
  }
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Pagination = styled(ReactPaginate).attrs({
  activeClassName: "active", // default to "disabled"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  list-style-type: none;
  padding: 0;
  li a {
    border-radius: 4px;
    padding-inline: 0.7rem;
    height: 32px;
    background: ${COLORS.white};
    border: 1px solid ${COLORS.softWhite2};
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  li.previous a,
  li.next a,
  li.break a {
  }
  li.active a {
    background-color: ${COLORS.green};
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const WarningTitle = styled.h6`
  margin: 0;
  font-size: 1rem;
  color: ${COLORS.blue};
`;

const WarningText = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-top: 8px;
  color: ${COLORS.blue};
`;

const Warning = styled.div`
  height: 100px;
  width: 100%;
  background: #4c6c8c0f;
  display: block;
  border-radius: 4px;
  border-left: 5px solid ${COLORS.blue};
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.2857rem;
  background: ${COLORS.white};
  border: 0;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
}
`;
const CardHeader = styled.div`
  margin: 15px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardTitle = styled.h4`
  color: ${COLORS.blue};
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 0.9rem;
  }
`;

const CardBody = styled.div`
  padding: 15px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    overflow: auto;
  }
`;

const TableReact = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
`;

const TableHead = styled.thead`
  width: 100%;
`;

const TableRow = styled.tr`
  width: 100%;
  &:nth-child(even) {
    background-color: ${COLORS.softWhite4};
  }
`;

const TableHeader = styled.th`
  padding: 5px;
  line-height: 2.5em;
  text-align: left;
  font-weight: 500;
  color: ${COLORS.grey};
  font-size: 15px;
  text-overflow: ellipsis;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding-right: 25px;
  }
`;

const TableBody = styled.tbody`
  overflow: auto;
`;

const TableCell = styled.td`
  padding: 12px 7px;
  vertical-allign: middle;
  margin: auto;
  text-overflow: ellipsis;
  border-top: 1px solid ${COLORS.softWhite3};
  color: ${COLORS.dark};
  &.flex {
    display: flex;
    gap: 12px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 0.8rem;
    padding-right: 25px;
  }
`;

export default ViewAccessCourse;
