import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../../constants/colors";
import { IoClose } from "react-icons/io5";
import Button from "../../atoms/Button";
import api from "../../../services/api";
import SCREEN_SIZES from "../../../constants/screenSizes";
import WizardBody from "../Wizard/WizardBody";
import LoadingScreen from "../../../pages/LoadingScreen";
import { RiInformationLine } from "react-icons/ri";

function ModalInfo({
  children,
  active,
  setModalInfo,
  title,
  subtitle,
  onClick,
  isClick,
  courseId
}) {
  const [loaded, setLoaded] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [dateStart, setdateStart] = useState("");
  const [dateEnd, setdateEnd] = useState("");
  const [links, setLinks] = useState("");
  const [filesList, setFilesList] = useState([]);
  const [containFile, setContainFile] = useState(false)
  const files = []

  useEffect(() => {
    getcurso(courseId);
  }, []);

  const getcurso = async (courseId) => {
    setLoaded(false);
    await api.get(`/cursos/${courseId}`).then((results) => {
      setName(results.data.nome);
      setDescription(results.data.descricao);
      setdateStart(results.data.dataInicio);
      setdateEnd(results.data.dataFim);
      setLinks(results.data.links);
    });
   
    await api.get(`/cursos/uploadFiles/${courseId}`, {
    })
      .then(response => {
        if (response.data.length > 0) {
          setContainFile(true)
        }
        for (let i = 0; i < response.data.length; i++) {
          files.push(response.data[i])
        } 
      })
      .catch(error => {
        console.log(error);
      });
      const renderList = files.map((item, index) => 
      <div key={index}>{item}</div>
    );

    setFilesList(renderList)
    setLoaded(true);
  };

  const downloadFile = async (file) => {
    const fileDonwload = file.props.children
    console.log('chamou download')
    await api.get(`/cursos/uploadFiles/donwloadFiles/${courseId}/${fileDonwload}`, {
      responseType: "blob"
    })
      .then(response => {
       const file = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

      })
      .catch(error => {
        console.log(error);
      });
  }

  
  return (
    <>
      <Wrapper />
        <Container active={active}>
          <Close>
            <IoClose
              onClick={() => setModalInfo(false)}
              fill={COLORS.grey}
              size={30}
              style={{ cursor: "pointer", float: "right" }}
            />
          </Close>
          <ModalTitle>{title}</ModalTitle>
          <ModalSubtitle>{subtitle}</ModalSubtitle>
          {children}
          <Card>
              <CardText>Nome: {name}</CardText>
              <CardText>Descrição: {description}</CardText>
              <CardText>Data de início: {dateStart}</CardText>
              <CardText>Data do fim: {dateEnd}</CardText>
              <CardText>Links: {links !== '' ? links : 'Nenhum link para exibir'}</CardText>
              <CardText>Arquivos:</CardText>
              {containFile && filesList.map((file, index)=> {
                  return (
                    <TableRow key={index}>
                      <TableCell>{file}</TableCell>
                        <TableCell>
                          <RiInformationLine onClick={() => downloadFile(file)} fill={COLORS.dark} style={{ cursor: 'pointer' }} size={'20px'}/>
                        </TableCell>
                    </TableRow>
                  );
                })}
              {!containFile &&
                <TableRow>
                  <TableCell>Nenhum arquivo foi inserido nesse curso</TableCell>
                </TableRow>
                  }
          </Card>
          <ActionGroup>
            <Button
              onClick={() => setModalInfo(false)}
              style={{
                padding: "12px 24px",
                background: COLORS.softWhite2,
                color: COLORS.grey,
              }}
            >
              Voltar
            </Button>
          </ActionGroup>
        </Container>
    </>
  );
}

const TableCell = styled.td`
  padding: 12px 7px;
  vertical-allign: middle;
  margin: auto;
  text-overflow: ellipsis;
  border-top: 1px solid ${COLORS.softWhite3};
  color: ${COLORS.dark};
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: .8rem;
    padding: 12px 15px;
  }
`;

const TableRow = styled.tr`
  width: 100%;
  &:nth-child(even) {
    background-color: ${COLORS.softWhite4};
  }
`;

const ButtonGroup = styled.div`
  gap: 12px;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

const Card = styled.div`
  max-width: 1000px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
  border-radius: 4px;
  overflow: auto;
`;

const CardTitle = styled.h4`
  margin-top: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${COLORS.blue};
`;

const CardText = styled.h6`
  margin-top: 16px;
  font-weight: 400;
  font-size: 1.2rem;
  color: ${COLORS.blue};
`;

const ActionGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 20px;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #00000099;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const Close = styled.div``;

const Container = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  position: absolute;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${COLORS.white};
  border-radius: 0.6rem;
  border: 1px solid ${COLORS.softWhite};
  min-height: 40vh;
  min-width: 30vw;
  width: 850px;
  max-width: 80vw;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
  overflow: auto;
`;

const ModalTitle = styled.h4`
  font-weight: 600;
  font-size: 1.3rem;
  text-align: center;
`;

const ModalSubtitle = styled.p`
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 24px;
`;

export default ModalInfo;
