import React, { useState } from 'react'
import styled from 'styled-components';
import SCREEN_SIZES from '../../constants/screenSizes';
import Navbar from '../molecules/Navbar';
import SideBar from '../molecules/SideBar';
import SideMobile from '../molecules/SideMobile';

function Root({ children, active }) {

  const [sideActive, setSideActive] = useState(false);
  const [page, setPage] = useState(active)

  return (
    <Container>
      <SideBar active={page} />
      <SideMobile open={sideActive} setSideActive={setSideActive} active={page} />
      <Content>
        <Navbar sideActive={sideActive} setSideActive={setSideActive} />
        <ContentBody>
          {children}
        </ContentBody>
      </Content>
    </Container>
  )
}


const Container = styled.div`
  display: grid;
  grid-template-areas: 'sidenav content';
  grid-template-columns: 327px auto;
  grid-template-rows: 100vh;

  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    grid-template-areas: 'content';
    grid-template-columns: auto;
  }
`;

const Content = styled.div``;

const ContentBody = styled.div`
  margin: 40px;
  
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin: 20px;
    max-width: calc(100vw - 40px);
  }
`;

export default Root