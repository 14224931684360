import React from 'react'
import Root from '../components/organisms/Root';
import SupportWizard from '../components/organisms/supportWizard'

function Support() {
    return (
        <Root active={'support'}>
            <SupportWizard />
        </Root>
    );
}


export default Support