import React from 'react';
import styled from 'styled-components';
import Logout from '../atoms/Logout';
import COLORS from '../../constants/colors';
import { HasAccess } from '@permify/react-role';
import SCREEN_SIZES from '../../constants/screenSizes';
import BurgerMobile from '../atoms/BurgerMobile';

function Navbar({ setSideActive }) {

  return (
    <Container>
      <BurgerMobile onClick={() => setSideActive(true)} />
      <HasAccess
        roles={["Administrador"]}>
        <Title>Você está navegando na área administrativa.</Title>
      </HasAccess>
      <HasAccess
        roles={["Medico"]}>
        <Title>Você está navegando na área para médicos.</Title>
      </HasAccess>
      <HasAccess
        roles={["Empresa"]}>
        <Title>Você está navegando na área para empresa.</Title>
      </HasAccess>
      <Logout />
    </Container>
  );
}

const Title = styled.h4`
  color: ${COLORS.white};
  font-weight: 400;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    display: none;
  }
`;


const Container = styled.header`
  height: 80px;
  width: 100%;
  background: ${COLORS.blue};
  color: ${COLORS.white};
  padding: 20px 40px;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
`;

export default Navbar;
