import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WizardBody from "../molecules/Wizard/WizardBody";
import styled from "styled-components";
import COLORS from "../../constants/colors";
import Button from "../atoms/Button";
import { BsArrowRight } from "react-icons/bs";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import api from "../../services/api";
import SCREEN_SIZES from "../../constants/screenSizes";

function Wizard(props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [apiResponse, setApiResponse] = useState("");

  const EspectroSchema = Yup.object().shape({
    reason: Yup.string().required("Selecione um reason da lista"),
    msg: Yup.string().required("Informa uma Mensagem para o suporte"),
  });

  const handleSubmitAPI = ({ reason, msg }) => {
    setLoading(true);

    api
      .post("/support", { reason, msg })
      .then(() => {
        navigate("/suggestedMedicines");
        setLoading(false);
      })
      .catch(({ response }) => {
        setApiResponse(response.data.erro);
      });
  };

  return (
    <>
      <Card>
        <WizardBody>
          <>
            <CardTitle>Suporte</CardTitle>

            <h4>
              Para consultar os medicamentos, basta seguir as etapas abaixo.
            </h4>

            <ol>
              <li>No menu lateral clique em Medicações Sugeridas</li>
              <li>Selecione o Espectro desejado.</li>
              <li>Selecione a Concentração.</li>
              <li>Selecione a Administração.</li>
              <li>As informações sobre os pacientes são opcionais.</li>
              <li>Pronto, a Medicação Sugerida será mostrada.</li>
            </ol>

            <h5>Você pode repetir esse processo sempre que necessário.</h5>
            <h5>
              No item minhas buscas você pode consultar todos os resultados das
              buscas anteriores.{" "}
            </h5>
            <h5>Em caso de dúvidas, favor entrar em contato conosco.</h5>

            <CardTitle>Contato</CardTitle>

            <Formik
              initialValues={{ reason: "", msg: "" }}
              validationSchema={EspectroSchema}
              onSubmit={(values) => {
                handleSubmitAPI(values);
              }}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Assunto <Required>*</Required>
                      </Label>
                      <Input
                        as="select"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        autoComplete="off"
                        name="reason"
                        value={values.reason}
                        disabled={loading}
                      >
                        <Option value>Selecione um assunto</Option>
                        <Option value="criticas">Críticas</Option>
                        <Option value="elogios">Elogios</Option>
                        <Option value="sugestoes">Sugestões</Option>
                        <Option value="duvidas">Dúvidas</Option>
                      </Input>
                      {errors.reason && touched.reason && (
                        <Errors>{errors.reason}</Errors>
                      )}
                    </InputContainer>
                  </InputGroup>
                  <InputGroup>
                    <InputContainer>
                      <Label>
                        Menssagem <Required>*</Required>
                      </Label>
                      <Input name="msg" value={values.msg} disabled={loading} />
                      {errors.msg && touched.msg && (
                        <Errors>{errors.msg}</Errors>
                      )}
                    </InputContainer>
                  </InputGroup>
                  <Info>
                    Para prosseguir com o contato, é necessário preencher o
                    formulário. Os campos com asterisco (*) são obrigatórios.
                  </Info>
                  <Errors>{apiResponse}</Errors>
                  <ButtonGroup>
                    <Button type="submit" style={{ paddingInline: "40px" }}>
                      Enviar <BsArrowRight fill={COLORS.white} size={20} />
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </>
        </WizardBody>
      </Card>
    </>
  );
}

const Option = styled.option`
  padding: 15px;
`;

const Errors = styled.span`
  display: block;
  font-size: 12px;
  margin: 6px;
  color: red;
`;

const Info = styled.span`
  display: block;
  font-size: 13px;
  margin-top: 20px;
  color: ${COLORS.grey};
  font-weight: 600;
`;

const Required = styled.span`
  color: red;
  font-weight: 600;
`;

const Card = styled.div`
  max-width: 800px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
  border-radius: 4px;
  @media only screen and (max-width: ${SCREEN_SIZES.md}) {
    max-width: 100%;
  }
`;

const CardTitle = styled.h4`
  margin-top: 16px;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${COLORS.blue};
`;

const InputGroup = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 0;
  flex-grow: 1;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 100%;
  }
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled(Field)`
  padding: 15px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${COLORS.softWhite2};
  color: ${COLORS.dark};
`;

const ButtonGroup = styled.div`
  gap: 12px;
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
`;

export default Wizard;
