import React from 'react'
import Root from '../components/organisms/Root';
import FilterFlow from '../components/organisms/FilterFlow'

function SuggestedMedicines() {
    return (
        <Root active={'suggestedMedicines'}>
            <FilterFlow/>
        </Root>
    );
}


export default SuggestedMedicines