const COLORS = {
    'white': '#FFF',
    'softWhite': '#E9E9E9',
    'softWhite2': '#e9eced',
    'softWhite3': '#EBEBEB',
    'softWhite4': '#F7F7F7',
    'blue': '#4C6C8C',
    'dark': '#2B2B2B',
    'greenSoft': '#eafff8',
    'green': '#64cb75',
    'greenAlternate': '#86d593',
    'greenDark': '#2e7e61',
    'grey': '#abb2bd'
}

export default COLORS;