import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import Button from "../atoms/Button";
import api from "../../services/api";
import COLORS from "../../constants/colors";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingScreen from "../../pages/LoadingScreen";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { TiWarning } from "react-icons/ti";
import Modal from "./Modal/ModalDelete";
import SCREEN_SIZES from "../../constants/screenSizes";
import { BsToggleOff, BsToggleOn, BsLockFill } from "react-icons/bs";

function TableUsers(props) {
  const navigate = useNavigate();

  const [usersList, setUsersList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [show, isShow] = useState(false);
  const [modalActivated, setModalActivated] = useState(false);
  const [userDeleted, setUserDeleted] = useState({});
  const [userActivated, setUserActivated] = useState({});
  const [userSelect, setUserSelect] = useState("");
  const [senha, setSenha] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [senhaConf, setSenhaConf] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 5;

  const [profileList, setProfileList] = useState([]);

  const [profile, setProfile] = useState();
  const [name, setName] = useState();

  const useQueryString = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQueryString();

  const paramQ = query.get("q") !== null ? query.get("q") : "";
  const paramProfile =
    query.get("profile") !== null ? query.get("profile") : "";

  const handleUserList = (list) => {
    setUsersList(list);
    setCurrentItems(list.slice(0, itemsPerPage));
    setPageCount(Math.ceil(list.length / itemsPerPage));
  };

  const handlePagination = (offset, list) => {
    const endOffset = offset + itemsPerPage;
    setCurrentItems(list.slice(offset, endOffset));
  };

  const getUsers = () => {
    api.get("/profiles").then((results) => {
      if (results) {
        setProfileList(results.data);
        console.log(`profileList: ${profileList}`)
      }
    });
    if(name){
      api.get(`/users/search?nome=${name}`).then((results) => {
        if (results) {
          handleUserList(results.data);
        }
        setLoaded(true);
      });
    }else{
      api
      .get("/users")
      .then((results) => {
        if (results) {
          handleUserList(results.data);
        }
        setLoaded(true);
      })
    }
  };

  useEffect(() => {
    getUsers();
  }, [loaded]);

  const handleDelete = (userId, user) => {
    setUserDeleted({ id: userId, usuario: user });
    setModalConfirm(true);
  };

  const handleActivate = (userId, user, status) => {
    setUserActivated({ id: userId, usuario: user, status });
    setModalActivated(true);
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;
    handlePagination(newOffset, usersList);
  };

  const clearFilters = () => {
    setLoaded(false)
    setName('')
    getUsers();
  };

  const handleFilters = () => {
    setLoaded(false)
    getUsers();
  };


  const handler_reset_pass = () => {
    if (!senha || !senhaConf || senha !== senhaConf) {
      setErrMsg("As senhas não são iguais!");
      return;
    } else {
      try {
        api
          .post("/rest_pass_admin", {
            user_id: userSelect,
            password: senha,
          })
          .then(() => {
            isShow(false);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      {modalConfirm && (
        <Modal
          setModalConfirm={setModalConfirm}
          active={modalConfirm}
          title={`Excluir usuário?`}
          subtitle={`Você tem certeza que quer excluir o usuário "${userDeleted.usuario}"?`}
          userId={userDeleted.id}
        >
          <Warning>
            <div>
              <TiWarning fill={COLORS.blue} size={28} />
            </div>
            <div>
              <WarningTitle>Atenção</WarningTitle>
              <WarningText>
                A exclusão desse usuário é definitiva. Todos os logs de
                atividades desse usuário também serão perdidos.
              </WarningText>
            </div>
          </Warning>
        </Modal>
      )}
      {modalActivated && (
        <Modal
          setModalConfirm={setModalActivated}
          active={modalActivated}
          title={`${
            userActivated.status === false ? "Ativar" : "Desativar"
          } usuário?`}
          subtitle={`Você tem certeza que deseja ${
            userActivated.status === false ? "ativar" : "desativar"
          } o usuário "${userActivated.usuario}"?`}
          userId={userActivated.id}
          userStatus={!userActivated.status}
          type={"activate"}
        >
          <Warning>
            <div>
              <TiWarning fill={COLORS.blue} size={28} />
            </div>
            <div>
              <WarningTitle>Atenção</WarningTitle>
              <WarningText>
                Ativar ou desativar um usuário impactará no acesso deste ao
                portal.
              </WarningText>
            </div>
          </Warning>
        </Modal>
      )}

      {show && (
        <Modal
          setModalConfirm={isShow}
          isClick={true}
          onClick={handler_reset_pass}
          active={show}
          title="Alterar senha do usuário"
          subtitle={`Deseja realmente alterar a senha desse uruário?`}
          userId={userActivated.id}
          userStatus={!userActivated.status}
          type={"activate"}
        >
          <Warning>
            <div>
              <TiWarning fill={COLORS.blue} size={28} />
            </div>
            <div>
              <WarningTitle>Atenção</WarningTitle>
              <WarningText>
                Alterar a senha de um usuário impactará no acesso deste ao
                portal.
              </WarningText>
            </div>
          </Warning>

          <br />
          <WarningTitle>Criar nova senha:</WarningTitle>
          <br />

          <Errors>{errMsg}</Errors>
          <div>
            <InputGroup>
              <InputContainer
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Label>Nova Senha:</Label>
                <TextInput
                  type="password"
                  onChange={(e) => {
                    setSenha(e.target.value);
                  }}
                />
              </InputContainer>

              <InputContainer
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Label>Confirmar Senha:</Label>
                <TextInput
                  type="password"
                  onChange={(e) => {
                    setSenhaConf(e.target.value);
                  }}
                />
              </InputContainer>
            </InputGroup>
          </div>
        </Modal>
      )}

      {!loaded && <LoadingScreen loading={!loaded} />}
      {loaded && (
        <>
          <CardHeader>
            <CardTitle>{props.tableName}</CardTitle>
            <Button
              onClick={() => navigate("/userRegister", { replace: true })}
            >
              Cadastrar Usuário
            </Button>
          </CardHeader>
          <Filters>
            <InputGroup>
              <InputContainer>
                <Label>Nome:</Label>
                <TextInput
                value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </InputContainer>

              <InputContainer>
                <Label>Perfil</Label>

                <Input
                  onChange={(e) => {
                    if (e.target.value !== "true") {
                      setProfile(e.target.value);
                    }
                  }}
                  as="select"
                  autoComplete="off"
                  name="profile"
                >
                  <Option value="">Selecione o perfil</Option>
                  <Option value="Administrador">Administrador</Option>
                  <Option value="Medico">Médico</Option>
                </Input>
              </InputContainer>

              <InputContainer>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    handleFilters();
                  }}
                >
                  Buscar
                </Button>
              </InputContainer>
              <InputContainer>
                    <Button
                     style={{backgroundColor: 'grey'}}
                     onClick={(e) => {
                      e.preventDefault();
                      clearFilters()
                    }} >
                      Limpar Busca
                    </Button>
                  </InputContainer>
            </InputGroup>
          </Filters>
          <Card>
            <CardBody>
              <TableReact>
                <TableHead>
                  <TableRow role="row">
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Nome
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Usuário
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Perfil
                    </TableHeader>
                    <TableHeader
                      colspan="1"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Status
                    </TableHeader>
                    <TableHeader
                      colspan="2"
                      role="columnheader"
                      title="Toggle SortBy"
                    >
                      Ações
                    </TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loaded &&
                    currentItems.length > 0 &&
                    currentItems.map((user) => {
                      return (
                        <TableRow key={user.id}>
                          <TableCell>{user.nome}</TableCell>
                          <TableCell>{user.usuario}</TableCell>
                          <TableCell>
                            {profileList.map((item) => {
                              if (item.id === user.profile) return item.profile;
                            })}
                          </TableCell>
                          <TableCell>
                            {user.status === false ? "Desativado" : "Ativo"}
                          </TableCell>
                          <TableCell className="flex">
                            <RiDeleteBinLine
                              onClick={() =>
                                handleDelete(user.id, user.usuario)
                              }
                              fill={COLORS.dark}
                              style={{ cursor: "pointer" }}
                              size={"20px"}
                            />
                            <BsLockFill
                              onClick={() => {
                                isShow(!show);
                                setUserSelect(user.id);
                              }}
                              fill={COLORS.dark}
                              style={{ cursor: "pointer" }}
                              size={"20px"}
                            />
                            {user.status !== false && (
                              <BsToggleOn
                                onClick={() =>
                                  handleActivate(
                                    user.id,
                                    user.usuario,
                                    user.status
                                  )
                                }
                                fill={COLORS.dark}
                                style={{ cursor: "pointer" }}
                                size={"20px"}
                              ></BsToggleOn>
                            )}
                            {user.status === false && (
                              <BsToggleOff
                                onClick={() =>
                                  handleActivate(
                                    user.id,
                                    user.usuario,
                                    user.status
                                  )
                                }
                                fill={COLORS.dark}
                                style={{ cursor: "pointer" }}
                                size={"20px"}
                              ></BsToggleOff>
                            )}
                          </TableCell>
                        </TableRow>
                      ); 
                    })}
                    {loaded && currentItems.length === 0 &&
                    <TableRow>
                      <TableCell>Nenhum usuário encontrado com os parâmetros informados.</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  }
                </TableBody>
              </TableReact>
            </CardBody>
          </Card>
          <Pagination
            breakLabel="..."
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={<HiChevronLeft size={20} fill={COLORS.dark} />}
            nextLabel={<HiChevronRight size={20} fill={COLORS.dark} />}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        </>
      )}
    </>
  );
}

const Filters = styled.form`
  width: 100%;
  background: ${COLORS.white};
  border-radius: 0.2857rem;
  padding: 15px;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const Option = styled.option`
  padding: 15px;
`;

const TextInput = styled.input`
  width: 100%;
  padding: 6px 10px;
  border-radius: 6px;
  margin-top: 10px;
  margin: 0;
  outline: 0;
  border: 1px solid #d5d5d5;
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 0 20px 0;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    width: 100%;
  }
`;

const Label = styled.label`
  font-size: 15px;
  color: ${COLORS.dark};
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid ${COLORS.softWhite2};
  color: ${COLORS.dark};
`;

const Pagination = styled(ReactPaginate).attrs({
  activeClassName: "active", // default to "disabled"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  list-style-type: none;
  padding: 0;
  li a {
    border-radius: 4px;
    padding-inline: 0.7rem;
    height: 32px;
    background: ${COLORS.white};
    border: 1px solid ${COLORS.softWhite2};
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  li.previous a,
  li.next a,
  li.break a {
  }
  li.active a {
    background-color: ${COLORS.green};
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

const WarningTitle = styled.h6`
  margin: 0;
  font-size: 1rem;
  color: ${COLORS.blue};
`;

const WarningText = styled.p`
  font-size: 0.9rem;
  margin: 0;
  margin-top: 8px;
  color: ${COLORS.blue};
`;

const Warning = styled.div`
  height: 100px;
  width: 100%;
  background: #4c6c8c0f;
  display: block;
  border-radius: 4px;
  border-left: 5px solid ${COLORS.blue};
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 0.2857rem;
  background: ${COLORS.white};
  border: 0;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${COLORS.softWhite};
}
`;
const CardHeader = styled.div`
  margin: 15px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardTitle = styled.h4`
  color: ${COLORS.blue};
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 0.9rem;
  }
`;

const CardBody = styled.div`
  padding: 15px;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    overflow: auto;
  }
`;

const TableReact = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
`;

const TableHead = styled.thead`
  width: 100%;
`;

const TableRow = styled.tr`
  width: 100%;
  &:nth-child(even) {
    background-color: ${COLORS.softWhite4};
  }
`;

const TableHeader = styled.th`
  padding: 5px;
  line-height: 2.5em;
  text-align: left;
  font-weight: 500;
  color: ${COLORS.grey};
  font-size: 15px;
  text-overflow: ellipsis;
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    padding-right: 25px;
  }
`;

const TableBody = styled.tbody`
  overflow: auto;
`;

const TableCell = styled.td`
  padding: 12px 7px;
  vertical-allign: middle;
  margin: auto;
  text-overflow: ellipsis;
  border-top: 1px solid ${COLORS.softWhite3};
  color: ${COLORS.dark};
  &.flex {
    display: flex;
    gap: 12px;
  }
  @media only screen and (max-width: ${SCREEN_SIZES.sm}) {
    font-size: 0.8rem;
    padding-right: 25px;
  }
`;

const Errors = styled.span`
  display: block;
  font-size: 12px;
  margin: 6px;
  color: red;
`;

export default TableUsers;
